import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'dorita-front';
  showButton = true;

  constructor(private config: NgSelectConfig, private router: Router) {
    this.config.notFoundText = 'No se encontraron resultados';
  }

  shouldShowFloatingButton(): boolean {
    const currentRoute = this.router.url;
    return !['/login', '/public/quotations/', '/public/payment-check'].includes(currentRoute);
  }
}

import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';

export const exportDataConfig: ExportDataConfig = {
  fileName: '',
  button: {
    label: 'Descargar',
    icon: '../assets/icons/download-icon.svg',
  },
  data: [],
  config: {
    columns: [],
    pageOritation: 'portrait',
    showDownloadDate: false,
    excelConfigOptions: { worksheet: { name: 'Datos' } },
    titleLines: [{ alignment: { vertical: 'middle', horizontal: 'center' }, value: 'Dorita' }],
  },
};

<div class="resume__content-wrapper">
    <div class="resume__content-box" *ngIf="paymentData && showSuccess">
        <div class="resume__top-box paid">
            <h2>Cotización #{{paymentData.number}}</h2>
        </div>
        <div class="body-wrapper">
            <div class="data-field-wrapper">
                <h2>Pago realizado exitosamente</h2>
                <div class="quotation-resume-wrapper">
                    <h3 class="items-resume-title">Detalle del pago</h3>
                    <div class="ammount-box">
                        <div class="ammount-item">
                            <span class="paragraph-3">Subtotal</span>
                            <span class="paragraph-2">${{paymentData.subtotal}}</span>
                        </div>
                        <div class="ammount-item">
                            <span class="paragraph-3">Impuestos</span>
                            <span class="paragraph-2">${{paymentData.tax}}</span>
                        </div>
                    </div>
                    <div class="total-box">
                        <span class="paragraph-3">Total</span>
                        <span class="paragraph-2">${{paymentData.total}}</span>
                    </div>
                </div>
            </div>
            <div class="footer-wrapper">
                <prt-button color="secondary" [callback]="goToQuotationResume">
                    Aceptar
                </prt-button>
            </div>
        </div>
    </div>
    <div class="resume__content-box" *ngIf="showError">
        <div class="resume__top-box cancelled">
            <h2>Cotización #{{paymentData.number}}</h2>
        </div>
        <div class="body-wrapper">
           <h2>Ha ocurrido un error al momento de realizar el pago</h2>
            <div class="footer-wrapper">
                <prt-button [callback]="goToQuotationResume">
                    Intentar de nuevo
                </prt-button>
            </div>
        </div>
    </div>
</div>
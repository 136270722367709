import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { Quotation } from 'src/app/models/quotation';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quotations-public',
  templateUrl: './quotations-public.component.html',
  styleUrls: ['./quotations-public.component.scss'],
})
export class QuotationsPublicComponent implements OnInit {
  quotationId: string | null | undefined = null;
  clientId: string | null = null;

  switch = true;

  quotationValues: Quotation = {
    createdAt: new Date(),
    id: '',
    note: '',
    clientId: '',
    userId: '',
    products: [
      {
        detail: '',
        name: '',
        productId: '',
        quantity: 0,
        totalPrice: 0,
        unitPrice: 0,
      },
    ],
    updatedAt: new Date(),
    Client: {
      name: '',
      number: 0,
    },
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    profits: 0,
    saleOrderId: '',
    status: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    showDetail: false,
  };

  clientValues: Partial<Client> = {
    createdAt: new Date(),
    email: '',
    name: '',
    id: '',
    address: '',
    dni: '',
    phone: '',
    phoneCode: '',
    updatedAt: new Date(),
    purchasesCount: '',
    userId: '',
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    syncId: '',
    zohoId: '',
  };

  //paymentModal
  showPaymentModal = false;
  showMarkAsPaidModal = false;
  showMarkAsCancelledModal = false;

  wpwlOptions = { style: 'card', locale: 'es' };
  checkoutId = '';
  checkoutPrepared = false;
  showRejectModal = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private quotationService: QuotationsService,
    private clientService: ClientsService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async params => {
      this.quotationId = params.get('quotationId');
      if (!this.quotationId) {
        const url = this.router.url;
        this.quotationId = url.split('/').pop();
      }
      if (this.quotationId) {
        this.quotationValues = await this.quotationService.getQuotationPublicById(this.quotationId);
        this.clientId = this.quotationValues.clientId;
        this.switch = this.quotationValues.showDetail;
        this.clientValues = await this.clientService.getClientPublicById(this.clientId);
      }
    });
  }

  //Share quotation
  rejectQuotation = () => {
    if (this.quotationId) {
      this.quotationService
        .cancelQuotation(this.quotationId)
        .then((res: any) => {
          this.checkDuplicateSuccessMessage('', 'Cotización rechazada');
          window.location.reload();
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Error', 'Ha ocurrido un error. Intente de nuevo');
    }
  };

  payQuotation = () => {
    if (this.quotationId) {
      this.quotationService
        .prepareQuotationPayment(this.quotationId)
        .then(res => {
          this.showPaymentModal = true;
          this.checkoutId = environment.emetecUrl + '/v1/paymentWidgets.js?checkoutId=' + (res as any).checkoutId;
          this.checkoutPrepared = true;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  markAsPaid = () => {
    if (this.quotationId) {
      this.quotationService
        .updateQuotation({ id: this.quotationId, status: 'PAID' })
        .then(res => {
          window.location.reload();
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  markAsCancelled = () => {
    if (this.quotationId) {
      this.quotationService
        .updateQuotation({ id: this.quotationId, status: 'CANCELED' })
        .then(res => {
          window.location.reload();
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //Payment Modal

  onPaymentModalStatus(evt: { isOpen: boolean }) {
    this.showPaymentModal = evt.isOpen;
  }

  onRejectModalStatus(evt: { isOpen: boolean }) {
    this.showRejectModal = evt.isOpen;
  }

  onMarkAsPaidModalStatus(evt: { isOpen: boolean }) {
    this.showMarkAsPaidModal = evt.isOpen;
  }

  onMarkAsCancelledModalStatus(evt: { isOpen: boolean }) {
    this.showMarkAsCancelledModal = evt.isOpen;
  }

  cancelPayment = () => {
    this.showPaymentModal = false;
    this.checkoutPrepared = false;
  };

  cancelReject = () => {
    this.showRejectModal = false;
  };

  cancelMarkAsPaid = () => {
    this.showMarkAsPaidModal = false;
  };

  cancelMarkAsCancelled = () => {
    this.showMarkAsCancelledModal = false;
  };

  handleShowRejectModal = () => {
    this.showRejectModal = true;
  };

  handleMarkAsPaidModal = () => {
    this.showMarkAsPaidModal = true;
  };

  handleMarkAsCancelledModal = () => {
    this.showMarkAsCancelledModal = true;
  };
}

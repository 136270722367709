<div class="create__content-wrapper no-padding">
  <div class="create__header-wrapper">
    <div class="header-wrapper center-align">
      <h1 class="text-primary" *ngIf="userCanUpdate || !quotationId">
        {{ !quotationId ? 'Agregar Nueva Cotización' : 'Editar Cotización #' + quotationValues.number }}
      </h1>
      <h1 class="text-primary" *ngIf="!userCanUpdate && quotationId">
        {{ 'Detalle de la cotización' + quotationValues.number }}
      </h1>
      <div class="spinnerMobile" [ngClass]="{ 'autosaving': isAutoSaving }">
        <div class="loadingspinner"></div>
        <span style="color: #C3C3C3">Guardando</span>
      </div>
    </div>
    <h1 *ngIf="quotationTotalPrice !== 0">{{ 'Total: $' + quotationTotalPrice }}</h1>
  </div>
  <div class="create__body-wrapper create__body-wrapper--block">
    <h2>Datos Generales</h2>
    <div class="create__form-wrapper">
      <div class="create__form-box">
        <prt-form
          #formTemplate
          [fields]="quotationForm"
          (change)="createQuotationFormChanges($event)"
          (isFormValid)="createQuotationFormISValid($event)"
          [validateIsRequired]="true"
        ></prt-form>
      </div>
    </div>

    <h3>Categorías de productos</h3>
    <div class="horizontal-list__wrapper" *ngIf="productCategoriesLoaded">
      <div class="horizontal-list__inner-box">
        <div
          class="horizontal-list__option-box"
          *ngFor="let productCategory of productCategories"
          (click)="productCategory.id === 'all' ? openAllProductsSelectionModal() : openProductSelectionModal(productCategory.id)"
        >
          <img [src]="productCategory.id === 'all' ? '../../../assets/icons/search-orange.svg' : (productCategory.picUrl ? productCategory.picUrl : '../../../assets/icons/products-icon.svg')" />
          <span class="horizontal-list__option-label">{{ productCategory.name }}</span>
        </div>
      </div>
    </div>

    <h3 style="margin-bottom: 0px">Productos Agregados</h3>
    <div class="table-external-wrapper table-external-wrapper--no-overflow-y" *ngIf="showProductsTable">
      <prt-table
        [rows]="productsTable"
        [columns]="tableColumns"
        [icons]="tableIcons"
        [actions]="tableActions"
        [pagination]="tablePagination"
        (changeCurrentPage)="handleChangePage($event)"
        [resultsLabel]="'Productos agregados'"
        [isLoading]="!isFetchingData"
        [showLoading]="true"
        class="hide-search-bar"
        [showActionsAtStart]="true"
        columnMode="force"
        [staticRowActions]="true">
      >
      </prt-table>
    </div>
  </div>
  <div class="create__footer-wrapper" *ngIf="userCanUpdate || !quotationId">
    <div class="spinner" [ngClass]="{ 'autosaving': isAutoSaving }">
      <div class="loadingspinner"></div>
      <span style="color: #C3C3C3">Guardando</span>
    </div>
    <prt-button [isInverted]="true" type="outline" [callback]="cancelCreation"> Volver </prt-button>
    <prt-button [isInverted]="true" type="solid" [callback]="createQuotation"> Calcular </prt-button>
  </div>
  <div class="create__footer-wrapper" *ngIf="!userCanUpdate && quotationId">
    <prt-button [isInverted]="true" type="solid" [callback]="cancelCreation"> Aceptar </prt-button>
  </div>

  <!-- All Products Form Modal -->
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showAllProductsModal"
    (onAction)="onAllProductsFormModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2>{{ getCurrentCategoryName }}</h2>
      <div class="create__form-wrapper">
        <div class="create__form-box">
          <prt-form
            #allProductsFormTemplate
            [fields]="allProductsForm"
            (change)="addAllProductsFormChanges($event)"
            (isFormValid)="addProductFormISValid($event)"
            class="form__wrapper--align-flex-end grey-already-selected"
            [validateIsRequired]="true"
            [isLoading]="allProductsIsLoading"
          ></prt-form>
        </div>
      </div>
      <div class="modal-buttons-wrapper">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelAllProductsAddition"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="addAllProducts"> Aceptar </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Product Form Modal -->
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showProductFormModal"
    (onAction)="onProductFormModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2>{{ getCurrentCategoryName }}</h2>
      <div class="create__form-wrapper">
        <div class="create__form-box">
          <prt-form
            #productFormTemplate
            [fields]="productForm"
            (change)="addProductFormChanges($event)"
            (isFormValid)="addProductFormISValid($event)"
            class="form__wrapper--align-flex-end grey-already-selected"
            [validateIsRequired]="true"
          ></prt-form>
        </div>
      </div>
      <div class="modal-buttons-wrapper">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelProductAddition"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="addProduct"> Aceptar </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Product Deletion Modal -->
  <prt-modal
    [displayButton]="false"
    size="sm"
    [isActive]="showDeletionModal"
    (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary">Confirmación</h2>
      <span class="paragraph-1">¿Estás seguro que deseas eliminar este producto?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelDeletion"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion"> Eliminar </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Share Quotation Modal -->
  <!-- <prt-modal [displayButton]="false" size="sm" [isActive]="showShareModal" (onAction)="onShareModalStatus($event)"
        [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Compartir </h2>
            <span class="paragraph-1">¿Quieres compartir esta cotización?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelSharing">
                    Cancelar
                </prt-button>
                <prt-button type="solid" [callback]="confirmSharing">
                    Compartir
                </prt-button>
            </div>
        </div>
    </prt-modal> -->
</div>

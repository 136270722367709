import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@protostech/protos-lib';
import { FormModule } from '@protostech/protos-lib';
import { LayoutModule } from '@protostech/protos-lib';
import { ModalModule } from '@protostech/protos-lib';
import { SideNavModule } from '@protostech/protos-lib';
import { TableModule } from '@protostech/protos-lib';
import { NgSelectModule } from '@ng-select/ng-select';

export const COMPONENTS = [];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    ButtonModule,
    FormModule,
    LayoutModule,
    ModalModule,
    SideNavModule,
    TableModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    ButtonModule,
    FormModule,
    LayoutModule,
    ModalModule,
    SideNavModule,
    TableModule,
  ],
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headersConfig: Record<string, string> = {};
    const token = this.jwtService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }
    for (const key of req.headers.keys()) {
      const value = req.headers.get(key);
      if (value !== null) {
        headersConfig[key] = value;
      }
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}

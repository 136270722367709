import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSeparator',
})
export class TextSeparatorPipe implements PipeTransform {
  transform(value: string[]): string {
    if (value.length == 0) {
      return 'No hay datos';
    }
    return value.join(', ');
  }
}

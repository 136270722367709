export interface Client {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  dni: string;
  address: string;
  phone: string;
  phoneCode: string;
  countryCode: string;
  purchasesCount: string;
  userId: string;
  User: {
    name: string;
  };
  number: number;
  numberStr: string;
  syncId: string;
  zohoId: string;
}

export interface ClientForm {
  email: string;
  name: string;
  address: string;
  dni: string;
  phone?: {
    dialCode?: string;
    number?: string;
    countryCode?: string;
  };
  userId: string;
}

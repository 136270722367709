export default {
  AUTH_LOGIN: '/auth/login',
  AUTH_ME: '/auth/me',
  USERS: '/users',
  ROLES: '/roles',
  TECHNICIANS: '/technicians',
  CLIENTS: '/clients',
  PRODUCTS: '/products',
  CATEGORIES: '/categories',
  QUOTATIONS: '/quotations',
  SEARCH: '/search',
  ROLES_SUBJECTS: '/roles/permission-subjects',
  ROLES_ACTIONS: '/roles/permission-action',
};

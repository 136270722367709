import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginForm, LoginResponse, Permission, User } from 'src/app/models/auth';
import endpoints from 'src/app/constants/endpoints';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { JwtService } from '../jwt/jwt.service';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private isAuthenticated = new BehaviorSubject<boolean>(false);
  private currentUserData = new BehaviorSubject<User | null>(null);

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private router: Router,
    private http: HttpClient,
    private ability: Ability,
  ) {}

  showModal() {
    throw new Error('Method not implemented.');
  }

  login(formData: LoginForm): Promise<void> {
    return this.apiService.post<LoginResponse>(endpoints.AUTH_LOGIN, formData).then(res => this.saveAuthData(res));
  }

  saveAuthData(data: LoginResponse): void {
    this.jwtService.saveItem('jwtToken', data.token);
    this.isAuthenticated.next(true);
  }

  savePermissions(permissions: Permission[]): void {
    this.jwtService.saveItem('permissions', JSON.stringify(permissions));
    this.updateAbility(permissions);
  }

  private updateAbility(permissions: Permission[]) {
    const { can, rules } = new AbilityBuilder(Ability);
    for (let i = 0; i < permissions.length; i++) {
      can(permissions[i].action, permissions[i].subject);
    }
    this.ability.update(rules);
  }

  purgeAuth(cb: () => void) {
    this.jwtService.destroyToken();
    this.isAuthenticated.next(false);
    this.currentUserData.next(null);
    this.ability.update([]);
    cb();
  }

  logout(): void {
    this.purgeAuth(() => {
      this.router.navigateByUrl(`/login`);
    });
  }

  getMe(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}${endpoints.AUTH_ME}`).pipe(
      map(data => {
        const { permissions } = data.role;
        if (permissions.length) {
          this.savePermissions(permissions);
          this.currentUserData.next(data);
        }
        return data;
      }),
    );
  }

  get authenticated(): boolean {
    return this.isAuthenticated.value;
  }

  get user(): User | null {
    return this.currentUserData.value;
  }
}

const errorMessages = {
  bad_request: 'Error de servidor',
  cannot_create_orders_at_this_time: 'No se pueden crear órdenes en este momento',
  cannot_create_technicians_from_users_module: 'No se pueden crear técnicos desde este módulo',
  cannot_delete_role_for_tehcnicians: 'No se puede eliminar el rol',
  cannot_edit_name_of_role_for_tehcnicians: 'No se puede editar el nombre del rol',
  client_not_found: 'Cliente no encontrado',
  duplicate_client: 'Cliente duplicado',
  duplicate_email: 'Email ya existente',
  duplicate_name: 'Nombre ya existente',
  duplicate_number: 'Número ya existente',
  duplicate_products_in_the_list: 'Producto duplicado',
  duplicate_role: 'Rol ya existente',
  invalid_email_or_password: 'Usuario o contraseña inválido',
  invalid_number_format: 'Formato del número invalido',
  invalid_old_password: 'Contraseña anterior inválida',
  missing_old_password: 'Falta la contraseña anterior',
  permissions_must_contain_at_least_one_element: 'Debe seleccionar al menos un permiso',
  product_price_should_not_be_less_than_inventory_price:
    'El precio del producto no puede ser menor al precio del inventario',
  quotation_already_was_paid: 'La cotización ya fue pagada',
  quotation_already_was_sent: 'Cotización enviada',
  quotation_not_found: 'Cotización no encontrada',
  role_not_found: 'Rol no encontrado',
  status_not_found: 'Estatus no encontrado',
  technician_not_found: 'Técnico no encontrado',
  the_client_has_quotations: 'El cliente tiene cotizaciones',
  the_role_is_being_used: 'El rol está en uso',
  there_are_inactive_products: 'Contiene productos que ya no están disponibles',
  unauthorized: 'Acción no autorizada',
  unprocessable_quotation: 'la cotización no puede ser procesada',
  user_not_found: 'Usuario no encontrado',
  inssuficient_stock: 'Stock agotado de',
  there_are_clients_associated_to_this_technician: 'Hay clientes asociados a este técnico',
  there_are_quotations_associated_to_this_technician: 'Hay cotizaciones asociadas a este técnico',
  add_products: 'Agregue productos',
  unknown_error: 'Error desconocido. Intente de nuevo',
};

export function getError(searchKey: any) {
  const keyExist = Object.entries(errorMessages);
  const data = keyExist.find(([key, val]) => key === searchKey);
  if (data) {
    return data[1];
  } else {
    return errorMessages['unknown_error'];
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { stringify as stringifyQs } from 'qs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly httpHeadersJson = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  constructor(public http: HttpClient) {}

  private toQueryString(query = {}): string {
    let queryString = '';
    if (Object.keys(query).length) {
      queryString = `?${stringifyQs(Object.assign({}, query), {
        encodeValuesOnly: true,
      })}`;
    }
    return queryString;
  }
  async get<T>(path: string, query = {}): Promise<T> {
    return await lastValueFrom(
      this.http.get<T>(`${environment.apiUrl}${path}${this.toQueryString(query)}`, { headers: this.httpHeadersJson }),
    );
  }
  async post<T>(path: string, body: unknown | FormData = {}, query = {}): Promise<T> {
    const httpHeaders = body instanceof FormData ? undefined : this.httpHeadersJson;
    return await lastValueFrom(
      this.http.post<T>(`${environment.apiUrl}${path}${this.toQueryString(query)}`, body, { headers: httpHeaders }),
    );
  }
  async put<T>(path: string, body: unknown | FormData = {}, query = {}): Promise<T> {
    const httpHeaders = body instanceof FormData ? undefined : this.httpHeadersJson;
    return await lastValueFrom(
      this.http.put<T>(`${environment.apiUrl}${path}${this.toQueryString(query)}`, body, { headers: httpHeaders }),
    );
  }

  async delete<T>(path: string, query = {}): Promise<T> {
    return await lastValueFrom(
      this.http.delete<T>(`${environment.apiUrl}${path}${this.toQueryString(query)}`, {
        headers: this.httpHeadersJson,
      }),
    );
  }
}

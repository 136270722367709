import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { exportDataConfig } from 'src/app/models/export-config';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreateProducts = false;
  userCanUpdateProducts = false;
  showAcceptButton = false;

  productsTableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  productsTableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editProduct(row['id'] as string),
    // deleteAction: (row: Record<string, unknown>) => this.deleteProduct(row['id'] as string),
  };

  productsTable: any[] = [];

  productsTableColumns = [
    {
      prop: 'name',
      name: 'Producto',
      minWidth: 750,
    },
    {
      prop: 'Category',
      name: 'Categoría',
      minWidth: 300,
    },
  ];

  productsTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  productId: string | null = null;

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;

  //Tab Menu
  tabMenuOptions = [
    {
      key: 'PRODUCTS',
      label: 'Productos',
    },
    {
      key: 'CATEGORIES',
      label: 'Categorías',
    },
  ];

  tabSelected = 'PRODUCTS';

  //Categories
  showCategoryDeletionModal = false;
  userCanCreateCategories = false;
  userCanUpdateCategories = false;

  categoriesTableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  categoriesTableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editProduct(row['id'] as string),
    // deleteAction: (row: Record<string, unknown>) => this.deleteProduct(row['id'] as string),
  };

  categoriesTable: any[] = [];

  categoriesTableColumns = [
    {
      prop: 'name',
      name: 'Categoría',
    },
  ];

  categoriesTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  categoryId: string | null = null;

  isCategoriesFetchingData = true;
  isExportingCategories = false;
  filterLabel: any;
  productExportDataConfig: ExportDataConfig | undefined;
  categoryExportDataConfig: ExportDataConfig | undefined;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private productService: ProductsService,
    private categoryService: CategoriesService,
  ) {
    // if (ability.can('delete', 'Product')) {
    //   this.tableColumns.push({
    //     prop: 'delete',
    //     name: '',
    //   });
    // }
  }

  async ngOnInit() {
    this.fillProductsTable();
    this.fillCategoriesTable();

    this.route.queryParamMap.subscribe(async params => {
      let sortBy = params.get('sortBy');
      const sortOrder = params.get('sortOrder');
      const page = Number(params.get('page'));

      if (sortBy === 'Category') {
        sortBy = sortBy + '.name';
      }

      this.buildQuery(sortBy, sortOrder, page);

      if (page) {
        if (this.tabSelected === 'PRODUCTS') {
          this.productsTablePagination['page'] = page;
        } else {
          this.categoriesTablePagination['page'] = page;
        }
      }

      if (this.tabSelected === 'PRODUCTS') {
        this.fillProductsTable(sortBy, sortOrder);
      } else {
        this.fillCategoriesTable(sortBy, sortOrder);
      }
    });

    this.canCreate();
    //this.productsTableActions['downloadAction'] = this.handleCreateProductsReport;
    //this.categoriesTableActions['downloadAction'] = this.handleCreateCategoriesReport;
    this.setProductExportationDataConfig();
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'name', sortOrder: 'asc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    if (this.tabSelected === 'PRODUCTS') {
      this.isFetchingData = true;
      if (!this.searchQuery['name']) {
        delete this.searchQuery['name'];
      }

      this.isFetchingData = true;
      return new Promise(async (res, rej) => {
        try {
          const products = await this.productService.getAllProducts(this.searchQuery);

          this.productsTable = products.data.map(product => {
            return {
              id: product.id,
              name: product.name,
              Category: product.Category ? product.Category.name : '-',
            };
          });

          this.productsTablePagination = { page: products.page, limit: products.limit, count: products.count };
          res(true);
        } catch (error) {
          rej(error);
        }
      }).finally(() => {
        this.isFetchingData = false;
      });
    } else {
      this.isCategoriesFetchingData = true;
      if (!this.searchQuery['name']) {
        delete this.searchQuery['name'];
      }
      this.isCategoriesFetchingData = true;
      return new Promise(async (res, rej) => {
        try {
          const categories = await this.categoryService.getAllCategories(this.searchQuery);

          this.categoriesTable = categories.data.map(category => {
            return {
              id: category.id,
              name: category.name,
            };
          });

          this.categoriesTablePagination = { page: categories.page, limit: categories.limit, count: categories.count };
          res(true);
        } catch (error) {
          rej(error);
        }
      }).finally(() => {
        this.isCategoriesFetchingData = false;
      });
    }
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query !== '') {
      // this.filterFormId?.resetForm();
      this.searchQuery = {
        ...this.searchQuery,
        ['name']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
  }

  resetSort() {
    delete this.searchQuery['sortKey'];
    delete this.searchQuery['sortOrder'];
    this.router.navigate(['/private/products']);
  }

  resetTablePagination() {
    this.productsTablePagination['page'] = 1;
    this.categoriesTablePagination['page'] = 1;
  }

  cleanSearch(key: string) {
    this.searchFormId?.resetForm();
    this.resetSearchQuery();
    this.resetSort();
    this.resetTablePagination();
    if (key === 'PRODUCTS') {
      this.fillProductsTable();
    } else {
      this.fillCategoriesTable();
    }
  }

  async setExportationDataConfig(key: string) {
    if (key === 'PRODUCTS') {
      await this.setProductExportationDataConfig();
    } else {
      await this.setCategoryExportationDataConfig();
    }
  }

  //Permission
  canCreate() {
    this.userCanCreateProducts = this.ability.can('create', 'Product');
    this.userCanCreateCategories = this.ability.can('create', 'Product');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Product')) {
      this.userCanUpdateProducts = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdateProducts = true;
      this.showAcceptButton = false;
    }

    if (this.ability.cannot('update', 'Category')) {
      this.userCanUpdateCategories = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdateCategories = true;
      this.showAcceptButton = false;
    }
  }

  // Error Messages

  checkDuplicateErrorMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //CRUD Products
  editProduct(productId: string) {
    this.router.navigate(['/private/products/edit'], { queryParams: { productId: productId } });
  }

  addProduct = () => {
    this.router.navigate(['/private/products/new']);
  };

  deleteProduct(productId: string) {
    this.productId = productId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.productId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.productId && this.productId !== '') {
      this.productService
        .deleteProduct(this.productId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Producto eliminado');
          this.fillProductsTable();
          this.showDeletionModal = false;
          this.productId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  //Products table
  async fillProductsTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const products = await this.productService.getAllProducts({
          page: this.productsTablePagination.page,
          limit: this.productsTablePagination.limit,
          type: 'GOODS',
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.productsTable = products.data.map(product => {
          return {
            id: product.id,
            name: product.name,
            Category: product.Category.name,
          };
        });

        this.productsTablePagination = { page: products.page, limit: products.limit, count: products.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleProductsChangePage(page: number) {
    this.productsTablePagination.page = page;
    this.router.navigate(['/private/products'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortProductsTable(evt: { dir: string; column: string }) {
    this.productsTablePagination.page = 1;
    this.router
      .navigate(['/private/products'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setProductExportationDataConfig());
  }

  //Products Report
  /**
   * @deprecated
   */
  handleCreateProductsReport = async () => {
    this.isExporting = true;
    this.searchQuery['limit'] = 0;
    if (!this.searchQuery['name']) {
      delete this.searchQuery['name'];
    }
    const products = await this.productService.getAllProducts(this.searchQuery);

    const productReport = products.data.map(product => {
      return {
        name: product.name,
        Category: product.Category.name,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportProducts(productReport);
    pdfMake.createPdf(pdfDefinition).download('Productos.pdf');
    // pdfMake.createPdf(pdfDefinition).open();
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };

  //CRUD Categories
  editCategory(categoryId: string) {
    this.router.navigate(['/private/categories/edit'], { queryParams: { categoryId: categoryId } });
  }

  addCategory = () => {
    this.router.navigate(['/private/categories/new']);
  };

  deleteCategory(categoryId: string) {
    this.categoryId = categoryId;
    this.showCategoryDeletionModal = true;
  }

  cancelCategoryDeletion = () => {
    this.categoryId = null;
    this.showCategoryDeletionModal = false;
  };

  confirmCategoryDeletion = () => {
    if (this.categoryId && this.categoryId !== '') {
      this.categoryService
        .deleteCategory(this.categoryId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Categoría eliminada');
          this.fillProductsTable();
          this.showCategoryDeletionModal = false;
          this.categoryId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  onDeleteCategoryModalStatus(evt: { isOpen: boolean }) {
    this.showCategoryDeletionModal = evt.isOpen;
  }

  //Categories table
  async fillCategoriesTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isCategoriesFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const categories = await this.categoryService.getAllCategories({
          page: this.categoriesTablePagination.page,
          limit: this.categoriesTablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.categoriesTable = categories.data.map(category => {
          return {
            id: category.id,
            name: category.name,
          };
        });

        this.categoriesTablePagination = { page: categories.page, limit: categories.limit, count: categories.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isCategoriesFetchingData = false;
    });
  }

  handleCategoriesChangePage(page: number) {
    this.productsTablePagination.page = page;
    this.router.navigate(['/private/products'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortCategoriesTable(evt: { dir: string; column: string }) {
    this.categoriesTablePagination.page = 1;
    this.router
      .navigate(['/private/products'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setCategoryExportationDataConfig());
  }

  //Categories Report
  /**
   * @deprecated
   */
  handleCreateCategoriesReport = async () => {
    this.isExportingCategories = true;
    this.searchQuery['limit'] = 0;
    if (!this.searchQuery['name']) {
      delete this.searchQuery['name'];
    }
    const categories = await this.categoryService.getAllCategories(this.searchQuery);

    const categoryReport = categories.data.map(category => {
      return {
        name: category.name,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportCategories(categoryReport);
    pdfMake.createPdf(pdfDefinition).download('Categorias.pdf');
    // pdfMake.createPdf(pdfDefinition).open();
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };

  async setProductExportationDataConfig() {
    this.productExportDataConfig = exportDataConfig;
    this.productExportDataConfig.data = await this.getProductReportData();
    this.productExportDataConfig.fileName = 'Productos';
    this.productExportDataConfig.config.columns = this.getProductReportColumns();
    this.productExportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.productExportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [350, 170];
    this.productExportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
  }

  async setCategoryExportationDataConfig() {
    this.categoryExportDataConfig = exportDataConfig;
    this.categoryExportDataConfig.data = await this.getCategoryReportData();
    this.categoryExportDataConfig.fileName = 'Categoría';
    this.categoryExportDataConfig.config.columns = this.getCategoryReportColumns();
    this.categoryExportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.categoryExportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [520];
    this.categoryExportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
  }

  private async getProductReportData() {
    this.searchQuery['limit'] = 0;
    if (!this.searchQuery['name']) {
      delete this.searchQuery['name'];
    }
    const products = await this.productService.getAllProducts(this.searchQuery);

    const productReport = products.data.map(product => {
      return {
        name: product.name,
        Category: product.Category.name,
      };
    });
    return productReport;
  }

  private async getCategoryReportData() {
    this.searchQuery['limit'] = 0;
    if (!this.searchQuery['name']) {
      delete this.searchQuery['name'];
    }
    const categories = await this.categoryService.getAllCategories(this.searchQuery);
    const categoryReport = categories.data.map(category => {
      return {
        name: category.name,
      };
    });
    return categoryReport;
  }

  private getProductReportColumns() {
    const columns = this.productsTableColumns.map(col => {
      const reportCol = {
        header: col.name,
        key: col.prop,
        width: 20,
        style: {
          alignment: {
            horizontal: 'left' as
              | 'center'
              | 'right'
              | 'fill'
              | 'left'
              | 'justify'
              | 'centerContinuous'
              | 'distributed'
              | undefined,
          },
        },
      };
      return reportCol;
    });
    return columns;
  }

  private getCategoryReportColumns() {
    const columns = this.categoriesTableColumns.map(col => {
      const reportCol = {
        header: col.name,
        key: col.prop,
        width: 20,
        style: {
          alignment: {
            horizontal: 'left' as
              | 'center'
              | 'right'
              | 'fill'
              | 'left'
              | 'justify'
              | 'centerContinuous'
              | 'distributed'
              | undefined,
          },
        },
      };
      return reportCol;
    });
    return columns;
  }
}

import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { Client, ClientForm } from 'src/app/models/clients';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private apiService: ApiService) {}

  getAllClients(query?: object): Promise<BaseRequest<Client>> {
    return this.apiService.get<BaseRequest<Client>>(endpoints.CLIENTS, query);
  }

  getClientById(id: string): Promise<Client> {
    return this.apiService.get<Client>(`${endpoints.CLIENTS}/${id}`);
  }

  updateClient(client: Partial<Client>): Promise<Client> {
    const { id } = client;
    delete client.id;
    return this.apiService.put<Client>(`${endpoints.CLIENTS}/${id}`, client);
  }

  deleteClient(id: string): Promise<Client> {
    return this.apiService.delete<Client>(`${endpoints.CLIENTS}/${id}`);
  }

  createClient(values: any) {
    return this.apiService.post(endpoints.CLIENTS, values);
  }

  getClientPublicById(id: string): Promise<Client> {
    return this.apiService.get<Client>(`/public${endpoints.CLIENTS}/${id}`);
  }
}

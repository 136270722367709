import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { User, UserForm } from 'src/app/models/auth';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService) {}

  getAllUsers(query?: object): Promise<BaseRequest<User>> {
    return this.apiService.get<BaseRequest<User>>(endpoints.USERS, query);
  }

  getUserById(id: string): Promise<User> {
    return this.apiService.get<User>(`${endpoints.USERS}/${id}`);
  }

  updateUser(user: Partial<User>): Promise<User> {
    const { id, password } = user;
    delete user.id;
    if (password === '') {
      delete user.password;
    }
    let k: keyof typeof user;
    for (k in user) {
      if (user[k] === '') {
        delete user[k];
      }
    }
    return this.apiService.put<User>(`${endpoints.USERS}/${id}`, user);
  }

  deleteUser(id: string): Promise<User> {
    return this.apiService.delete<User>(`${endpoints.USERS}/${id}`);
  }

  createUser(values: UserForm) {
    let k: keyof typeof values;
    for (k in values) {
      if (values[k] === '') {
        delete values[k];
      }
    }
    return this.apiService.post(endpoints.USERS, values);
  }
}

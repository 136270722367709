import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { Product, ProductForm } from 'src/app/models/products';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private apiService: ApiService) {}

  getAllProducts(query?: Record<string, string | number>): Promise<BaseRequest<Product>> {
    // clear empty query params
    let k: keyof typeof query | string;
    for (k in query) {
      if (query && (query[k] === '' || query[k] === null)) {
        delete query[k];
      }
    }
    return this.apiService.get<BaseRequest<Product>>(endpoints.PRODUCTS, query);
  }

  getProductById(id: string): Promise<Product> {
    return this.apiService.get<Product>(`${endpoints.PRODUCTS}/${id}`);
  }

  updateProduct(product: Partial<Product>): Promise<Product> {
    const { id } = product;
    delete product.id;
    return this.apiService.put<Product>(`${endpoints.PRODUCTS}/${id}`, product);
  }

  deleteProduct(id: string): Promise<Product> {
    return this.apiService.delete<Product>(`${endpoints.PRODUCTS}/${id}`);
  }

  createProduct(values: ProductForm) {
    return this.apiService.post(endpoints.PRODUCTS, values);
  }
}

import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { BaseRequest } from 'src/app/models/request';
import { Technician, TechnicianForm } from 'src/app/models/technician';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class TechniciansService {
  constructor(private apiService: ApiService) {}

  getAllTechnicians(query?: object): Promise<BaseRequest<Technician>> {
    return this.apiService.get<BaseRequest<Technician>>(endpoints.TECHNICIANS, query);
  }

  getTechnicianById(id: string): Promise<Technician> {
    return this.apiService.get<Technician>(`${endpoints.TECHNICIANS}/${id}`);
  }

  updateTechnician(technician: Partial<Technician>): Promise<Technician> {
    const { id, password } = technician;
    delete technician.id;
    if (!technician.pic && !technician.picUrl) {
      delete technician.pic;
      delete technician.picUrl;
      return this.apiService.put<Technician>(`${endpoints.TECHNICIANS}/${id}`, technician);
    } else {
      const formData = new FormData();
      let k: keyof typeof technician;
      for (k in technician) {
        if (technician[k]) {
          formData.append(`${k}`, technician[k] as string | Blob);
        }
      }
      return this.apiService.put<Technician>(`${endpoints.TECHNICIANS}/${id}`, formData);
    }
  }

  deleteTechnician(id: string): Promise<Technician> {
    return this.apiService.delete<Technician>(`${endpoints.TECHNICIANS}/${id}`);
  }

  createTechnician(values: any) {
    const formData = new FormData();
    let k: keyof typeof values;
    for (k in values) {
      if (values[k]) {
        formData.append(`${k}`, values[k] as string | Blob);
      }
    }
    return this.apiService.post(endpoints.TECHNICIANS, formData);
  }

  //Esto esta mal y hay que cambiarlo
  isTechnician(roleName: string) {
    return roleName === 'Técnico';
  }
}

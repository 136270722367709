<div class="resume__content-wrapper send-view">
  <div class="resume__content-box">
    <div class="resume__top-box">
      <div class="resume__technician-img">
        <img
          [src]="quotationValues.User.picUrl ? quotationValues.User.picUrl : '../../../assets/icons/user-icon.svg'"
        />
      </div>
      <h2>Enviar Cotización</h2>
    </div>
    <div class="resume__header-wrapper">
      <div class="header__upper-box">
        <div class="header__order-number-box">
          <img src="../../../assets/icons/quotations-icon.svg" alt="quotation-icon" class="cursor-default" />
          <span class="paragraph-4"># {{ quotationValues.number }}</span>
        </div>
        <img
          src="../../../assets/icons/edit.svg"
          alt="edit-icon"
          (click)="editQuotation()"
          *ngIf="isCreatedStatus || isSentStatus"
        />
      </div>
      <span class="paragraph-6">{{ quotationValues.User.name }}</span>
    </div>
    <div class="body-wrapper">
      <div class="client-info-label" (click)="showClientData = !showClientData">
        <h5>Información del cliente</h5>
        <img src="../../../assets/icons/arrow-icon.svg" alt="arrow" [ngClass]="{ isOpen: showClientData }" />
      </div>
      <div class="data-field-wrapper no-margin-bottom">
        <div class="data-field-wrapper no-margin-bottom" *ngIf="showClientData">
          <div class="data-field-box data-field-box--block">
            <h4>Cliente</h4>
            <span class="paragraph-2">{{ quotationValues.Client.name }}</span>
          </div>
          <div class="data-field-box data-field-box--half-size">
            <h4>Identificación</h4>
            <span class="paragraph-2">{{ clientValues.dni ? clientValues.dni : '-' }}</span>
          </div>
          <div class="data-field-box data-field-box--half-size">
            <h4>Teléfono</h4>
            <span class="paragraph-2">{{ clientValues.phone }}</span>
          </div>
          <div class="data-field-box data-field-box--block">
            <h4>Dirección</h4>
            <span class="paragraph-2">{{ clientValues.address }}</span>
          </div>
          <div class="data-field-box data-field-box--block">
            <h4>Correo electrónico</h4>
            <span class="paragraph-2">{{ clientValues.email }}</span>
          </div>
          <div class="data-field-box data-field-box--block">
            <h4>Nota</h4>
            <span class="paragraph-2">{{ quotationValues.note ? quotationValues.note : '-' }}</span>
          </div>
        </div>

        <div class="quotation-resume-wrapper">
          <h3 class="items-resume-title">{{ switch ? 'Detalle' : 'Resumen' }}</h3>
          <div class="products-box" *ngIf="!switch">
            <span class="paragraph-2" *ngFor="let product of quotationValues.products">
              {{ product.name }}
            </span>
          </div>
          <div class="products-box" *ngIf="switch">
            <span class="paragraph-2" *ngFor="let product of quotationValues.products">
              {{ product.quantity + ' ' + product.unit + ' de ' + product.name }}
            </span>
          </div>
          <div class="ammount-box">
            <div class="ammount-item">
              <span class="paragraph-3">Subtotal</span>
              <span class="paragraph-2">${{ quotationValues.subtotal }}</span>
            </div>
            <div class="ammount-item">
              <span class="paragraph-3">Impuestos</span>
              <span class="paragraph-2">${{ quotationValues.tax }}</span>
            </div>
          </div>
          <div class="total-box">
            <span class="paragraph-3">Total</span>
            <span class="paragraph-2">${{ quotationValues.total }}</span>
          </div>
        </div>
      </div>

      <div class="toggle-wrapper">
        <h4>Mostrar detalles</h4>
        <input type="checkbox" id="switch" class="checkbox" [(ngModel)]="switch" />
        <label for="switch" class="toggle"></label>
      </div>

      <div class="extra-info-box">
        <span class="paragraph-3">Tu ganancia a Recibir</span>
        <span class="paragraph-2">${{ quotationValues.profits }}</span>
      </div>

      <div class="warning-message-box">
        <h4>Esta cotización será valida por 48 horas o según disponibilidad de inventario</h4>
      </div>

      <div class="footer-wrapper">
        <prt-button type="solid" [callback]="confirmSharing">
          <h2>Enviar Cotización</h2>
        </prt-button>
        <prt-button color="secondary" [callback]="cancelSharing">
          <h2>Aceptar</h2>
        </prt-button>
      </div>
    </div>
  </div>
</div>

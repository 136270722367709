import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { UserResolver } from './resolvers/user.resolver';
import { AuthGuard } from './guards/auth.guard';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  {
    path: 'private',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    resolve: { result: UserResolver },
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'technicians',
        loadChildren: () => import('./modules/technicians/technicians.module').then(m => m.TechniciansModule),
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
      },
      {
        path: 'quotations',
        loadChildren: () => import('./modules/quotations/quotations.module').then(m => m.QuotationsModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
      },
    ],
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'quotations',
        loadChildren: () =>
          import('./modules/quotations-public/quotations-public.module').then(m => m.QuotationsPublicModule),
      },
      {
        path: 'payment-check',
        loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule),
      },
    ],
  },

  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export default {
  emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  passwordPattern: /^(?=.*?[A-Z])(?=.*?[*@!#%&()^~{}]).{5,}$/,
  passwordSpecialCharPattern: /[*@!#%&()^~{}]+/,
  passwordCapsPattern: /[A-Z]+/,
  lettersPattern: /^\D+$/,
  namePattern: /([A-Za-z])+/,
  numberPattern: /^[0-9]*$/,
  dniPattern: /^[a-zA-Z0-9._-]+$/,
  greaterThanZeroPattern: /^[1-9]\d*$/,
  greaterThanZeroWithDecimals: /^(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/,
  phonePattern: /^(\+)?[0-9]*$/,
};

import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { Role, RoleForm } from 'src/app/models/auth';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private apiService: ApiService) {}

  getAllRoles(query?: object): Promise<BaseRequest<Role>> {
    return this.apiService.get<BaseRequest<Role>>(endpoints.ROLES, query);
  }

  getRolById(id: string): Promise<Role> {
    return this.apiService.get<Role>(`${endpoints.ROLES}/${id}`);
  }

  updateRol(role: Partial<Role>): Promise<Role> {
    const { id } = role;
    delete role.id;
    return this.apiService.put<Role>(`${endpoints.ROLES}/${id}`, role);
  }

  deleteRol(id: string): Promise<Role> {
    return this.apiService.delete<Role>(`${endpoints.ROLES}/${id}`);
  }

  createRol(values: RoleForm) {
    // const formData = new FormData();
    // formData.append('name', values.name);
    // formData.append('description', values.description);
    // formData.append('permissions', values.permissions.join(','));
    return this.apiService.post(endpoints.ROLES, values);
  }

  getAllSubjects(query?: object): Promise<string[]> {
    return this.apiService.get<string[]>(endpoints.ROLES_SUBJECTS, query);
  }

  getAllActions(query?: object): Promise<string[]> {
    return this.apiService.get<string[]>(endpoints.ROLES_ACTIONS, query);
  }
}

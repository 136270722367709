export interface LoginForm {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface User {
  createdAt: Date;
  address: string;
  dni: string;
  email: string;
  id: string;
  name: string;
  phone: string;
  lastLogin: Date;
  role: Role;
  roleId: string;
  updatedAt: Date;
  password?: string;
  picUrl?: string;
}

export interface Role {
  description: string;
  id: string;
  name: string;
  permissions: Permission[];
}

export interface Permission {
  action: string;
  subject: string;
}

export interface UserForm {
  email: string;
  password: string;
  name: string;
  code?: string;
  address?: string;
  dni?: string;
  phone?: string;
  company?: string;
  roleId: string;
  pic?: Blob | string;
}

export interface RoleForm {
  description: string;
  name: string;
  permissions: Permission[];
}

const roles = {
  'cc418c1a-12da-4c4d-b77e-5ec9051aaea2': 'Super admin',
  'c83d1885-6e74-4a10-8f05-34f154fc0d7d': 'Solo lectura',
};

export function getRole(searchKey: any) {
  const keyExist = Object.entries(roles);
  const data = keyExist.find(([key, val]) => key === searchKey);
  if (data) {
    return data[1];
  } else {
    return '';
  }
}

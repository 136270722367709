<div class="create__content-wrapper no-padding">
    <div class="create__header-wrapper">
        <h1 class="text-primary"> Perfil </h1>
        <h4 *ngIf="loadedData"> {{technicianValues.name}}</h4>
    </div>

    <div class="create__body-wrapper create__body-wrapper--no-center no-overflow-bar">
        <div class="create__img-wrapper">
            <div class="img-box">
                <img [src]="technicianValues.picUrl && !temporarySelectedImg ? technicianValues.picUrl : technicianFormData.pic ? temporarySelectedImg : '../../../assets/icons/user-icon.svg'"
                    alt="profile image">
            </div>
            <div class="add-icon">
                <img *ngIf="!technicianValues.picUrl && !technicianFormData.pic"
                    src="../../../assets/icons/add-icon.svg" alt="add">
                <img *ngIf="technicianValues.picUrl || technicianFormData.pic" src="../../../assets/icons/edit.svg"
                    alt="edit">
                <input type="file" (change)="attachImg($event)" accept=".png, .jpg">
            </div>
        </div>
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="profileForm" (change)="profileFormChanges($event)"
                    (isFormValid)="profileFormISValid($event)"></prt-form>
            </div>
        </div>

<!--        <div class="logout-button-wrapper">-->
<!--            <prt-button color="secondary" [callback]="logOut">-->
<!--                Cerrar Sesión-->
<!--            </prt-button>-->
<!--        </div>-->
    </div>

    <div class="create__footer-wrapper">
        <!-- <prt-button type="outline" [callback]="cancelChanges">
            Cancelar
        </prt-button> -->
      <prt-button color="secondary" [callback]="logOut">
            Cerrar Sesión
      </prt-button>
      <prt-button [isInverted]="true" type="solid" [callback]="saveChanges">
          Guardar
      </prt-button>
    </div>
    <prt-modal [displayButton]="false" size="sm" [isActive]="showModal"
               (onAction)="onModalStatus($event)"
               [hasCloseButton]="false">
      <div class="modal-wrapper">
        <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
        <span class="paragraph-1">¿Deseas finalizar sesión?</span>
        <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
          <prt-button [isInverted]="true" type="outline" class="prt__button--outline" [callback]="returnDorita">
            Cancelar
          </prt-button>
          <prt-button [isInverted]="true" type="solid" [callback]="confirmationExit">
            Aceptar
          </prt-button>
        </div>
      </div>
    </prt-modal>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { User, UserForm } from 'src/app/models/auth';
import { getError } from 'src/app/models/error';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UsersService } from 'src/app/services/users/users.service';
import validation from 'src/app/utils/validation';
import Validation from 'src/app/utils/validation';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss'],
})
export class UsersCreateComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) userFormId: An507FormRefInterface | undefined;

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  userForm: TextBoxField[] | DropdownField[] = [
    {
      controlType: 'textBox',
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre y Apellido',
      required: true,
      width: 'md',
      maxLength: 100,
      minLength: 2,
      regex: validation.namePattern,
      validationErrorMessage: 'El nombre no debe contener números',
      showErrorsUx: true,
      placeholder: 'Ingrese nombre y apellido',
    },
    {
      controlType: 'textBox',
      key: 'email',
      type: 'string',
      value: '',
      label: 'Email',
      required: true,
      width: 'md',
      maxLength: 100,
      regex: validation.emailPattern,
      validationErrorMessage: 'Email inválido',
      showErrorsUx: true,
      placeholder: 'Ingrese email',
    },
    {
      controlType: 'textBox',
      key: 'password',
      type: 'password',
      value: '',
      label: 'Contraseña',
      required: true,
      width: 'md',
      minLength: 6,
      maxLength: 30,
      validationErrorMessage: 'Contraseña debe contener más de 5 caracteres y menos de 31',
      showErrorsUx: true,
      placeholder: 'Ingrese contraseña',
      // noMargin: true,
    },
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'roleId',
      label: 'Rol',
      required: true,
      width: 'md',
      selectOptions: [],
      validationErrorMessage: 'Debes seleccionar un rol',
      showErrorsUx: true,
      noMargin: true,
      placeholder: 'Seleccione un rol',
    }),
  ];

  isUserFormValid = false;

  userFormData: UserForm = {
    email: '',
    password: '',
    name: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roleId: null,
  };

  isFormLoaded = false;
  userId: string | null = null;

  userValues: User = {
    createdAt: new Date(),
    email: '',
    name: '',
    id: '',
    role: {
      description: '',
      id: '',
      name: '',
      permissions: [],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roleId: null,
    address: '',
    dni: '',
    phone: '',
    picUrl: '',
    updatedAt: new Date(),
    lastLogin: new Date(),
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private userService: UsersService,
    private rolesService: RolesService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    const roles = await this.rolesService.getAllRoles({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });
    this.userForm[3].selectOptions = roles.data.map(rol => {
      return {
        label: rol.name,
        value: rol.id,
      };
    });

    this.route.queryParamMap.subscribe(async params => {
      this.userId = params.get('userId');
      if (this.userId) {
        this.isFormLoaded = false;
        this.userValues = await this.userService.getUserById(this.userId);
        this.userForm[2].placeholder = '••••••••';

        setTimeout(() => {
          this.fillForm();
          this.isFormLoaded = true;
          this.canUpdate();
          this.userForm[2].placeholder = '••••••••';
          this.userFormId?.form.controls['password'].setValidators([Validators.minLength(6), Validators.maxLength(30)]);
          this.userFormId?.form.controls['password'].updateValueAndValidity();
        });
      } else {
        this.isFormLoaded = true;
        this.fillForm();
      }
    });
  }

  async fillForm() {
    this.userFormId?.updateForm({
      email: this.userValues.email,
      password: '',
      name: this.userValues.name,
      roleId: this.userValues.roleId,
    });
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'User');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'User')) {
      this.userFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Create User
  createUser = () => {
    if (this.userValues?.id && this.isUserFormValid) {
      const formData = {
        name: this.userFormData.name,
        email: this.userFormData.email,
        password: this.userFormData.password,
        roleId: this.userFormData.roleId,
        id: this.userValues.id,
      };
      this.userService
        .updateUser(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Usuario editado');
          this.router.navigate(['/private/admin/users']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else if (!this.userValues?.id && this.isUserFormValid) {
      this.userService
        .createUser(this.userFormData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Usuario creado');
          this.router.navigate(['/private/admin/users']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false);
      this.userForm.forEach((item: any) => {
        if (item.required) {
          this.userFormId?.form.controls[item.key].markAsDirty();
        }
      });
    }
  };

  createUserFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.userFormData, { ...(evt as UserForm) });
    }
  }

  createUserFormISValid(evt: boolean) {
    this.isUserFormValid = evt;
  }

  cancelCreation = () => {
    this.router.navigate(['/private/admin/users']);
  };

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getFormErrors?: boolean, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors();
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors() {
    const error: string[] = [];
    this.userForm.forEach(element => {
      const isInvalid = (this.userFormId as any).getFormValidationErrorsByKey(element.key);
      if (isInvalid.length > 0) {
        error.push(element.label);
      }
    });
    return error.join(', ');
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import {
  An507FormRefInterface,
  BasicFormBase,
  DropdownField,
  TextBoxField,
  TextBoxPhoneField,
} from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Client, ClientForm } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { AuthService } from 'src/app/services';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import validation from 'src/app/utils/validation';

@Component({
  selector: 'app-clients-create',
  templateUrl: './clients-create.component.html',
  styleUrls: ['./clients-create.component.scss'],
})
export class ClientsCreateComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) clientFormId: An507FormRefInterface | undefined;

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  clientForm: TextBoxField[] | DropdownField[] | TextBoxPhoneField[] = [
    {
      controlType: 'textBox',
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre y Apellido',
      required: true,
      width: 'lg',
      maxLength: 100,
      minLength: 2,
      regex: validation.namePattern,
      validationErrorMessage: 'El nombre no debe contener números',
      showErrorsUx: true,
      placeholder: 'Ingrese nombre y apellido',
    },
    {
      controlType: 'textBox',
      key: 'dni',
      type: 'text',
      value: '',
      label: 'Identificación',
      required: false,
      width: 'lg',
      maxLength: 100,
      regex: validation.dniPattern,
      validationErrorMessage: 'Identificación inválida',
      showErrorsUx: true,
      placeholder: 'Ingrese identificación (opcional)',
    },
    {
      controlType: 'textBoxPhone',
      key: 'phone',
      type: 'string',
      value: '',
      label: 'Número de contacto',
      required: true,
      width: 'lg',
      //regex: validation.phonePattern,
      validationErrorMessage: 'Número de contacto incorrecto',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'email',
      type: 'text',
      value: '',
      label: 'Correo electrónico',
      required: false,
      width: 'lg',
      maxLength: 50,
      regex: validation.emailPattern,
      validationErrorMessage: 'Correo electrónico inválido',
      showErrorsUx: true,
      placeholder: 'Ingrese correo electrónico (opcional)',
    },
    {
      controlType: 'textBox',
      key: 'address',
      type: 'text',
      value: '',
      label: 'Dirección',
      required: true,
      width: 'lg',
      maxLength: 100,
      validationErrorMessage: 'Dirección inválida',
      showErrorsUx: true,
      placeholder: 'Ingrese dirección',
    },
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'userId',
      label: 'Técnico',
      width: 'lg',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Técnico inválido',
      showErrorsUx: true,
      placeholder: 'Seleccione un técnico',
    }),
  ];

  isClientFormValid = false;

  clientFormData: Partial<ClientForm> = {
    email: '',
    name: '',
    address: '',
    dni: '',
    phone: {
      dialCode: '',
      number: '',
      countryCode: '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: null,
  };

  isFormLoaded = false;
  clientId: string | null = null;

  clientValues: Partial<Client> = {
    createdAt: new Date(),
    email: '',
    name: '',
    id: '',
    address: '',
    dni: '',
    phone: '',
    phoneCode: '',
    updatedAt: new Date(),
    purchasesCount: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: null,
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    syncId: '',
    zohoId: '',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private createdPdfService: CreatedPdfService,
    private clientService: ClientsService,
    private technicianService: TechniciansService,
    private authService: AuthService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    try {
      const me = await firstValueFrom(this.authService.getMe());
      if (this.technicianService.isTechnician(me.role.name)) {
        this.clientForm = this.clientForm.filter(i => i.key != 'userId');
        this.clientFormId?.form.controls['userId'].setValue(me.id, { emitValue: true });
      } else {
        this.getTechnicians();
        this.clientFormId?.form.controls['userId'].setValue(null, { emitValue: true });
      }
    } catch (error) {
      console.error(error);
      // this.router.navigate(['/login']);
    }

    this.route.queryParamMap.subscribe(async params => {
      this.clientId = params.get('clientId');
      if (this.clientId) {
        this.isFormLoaded = false;
        this.clientValues = await this.clientService.getClientById(this.clientId);

        setTimeout(() => {
          this.fillForm();
          this.isFormLoaded = true;
          this.canUpdate();
        });
      } else {
        this.isFormLoaded = true;
      }
    });
  }

  async fillForm() {
    this.clientFormId?.updateForm({
      email: this.clientValues.email,
      password: '',
      name: this.clientValues.name,
      address: this.clientValues.address,
      dni: this.clientValues.dni,
      phone: {
        dialCode: this.clientValues.phoneCode,
        number: this.clientValues.phone,
        countryCode: this.clientValues.countryCode,
      },
      purchasesCount: this.clientValues.purchasesCount,
      userId: this.clientValues.userId,
      user: this.clientValues.User,
      number: this.clientValues.number,
      numberStr: this.clientValues.numberStr,
      syncId: this.clientValues.syncId,
      zohoId: this.clientValues.zohoId,
    });
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Client');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Client')) {
      this.clientFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Create Client
  createClient = async () => {
    const formData = {
      name: this.clientFormData.name,
      email: this.clientFormData.email,
      id: this.clientValues.id,
      address: this.clientFormData.address,
      dni: this.clientFormData.dni,
      phoneCode: this.clientFormData.phone?.dialCode,
      phone: this.clientFormData.phone?.number,
      countryCode: this.clientFormData.phone?.countryCode,
      userId: this.clientFormData.userId,
    };
    if (!formData.dni) {
      delete formData.dni;
    }
    if (!formData.email) {
      delete formData.email;
    }
    if (!this.clientValues?.id) {
      delete formData.id;
    }
    const me = await firstValueFrom(this.authService.getMe());
    if (this.technicianService.isTechnician(me.role.name)) {
      this.clientFormData.userId = me.id;
    }
    if (this.clientFormData.userId) {
      if (this.clientValues?.id) {
        this.clientService
          .updateClient(formData)
          .then(res => {
            this.checkDuplicateSuccessMessage('', 'Cliente editado');
            this.router.navigate(['/private/clients']);
          })
          .catch(err => {
            console.error(err);
            if (err.error.code) {
              this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
            } else {
              this.checkDuplicateErrorMessage('Error', err.error.message);
            }
          });
      } else if (!this.clientValues?.id) {
        this.clientService
          .createClient(formData)
          .then((res: any) => {
            this.checkDuplicateSuccessMessage('', 'Cliente creado');
            if (localStorage.getItem('quotationData')) {
              this.setClientDataInQuotation(res.id);
              this.router.navigate(['/private/quotations/new']);
            } else {
              this.router.navigate(['/private/clients']);
            }
          })
          .catch(err => {
            console.error(err);
            if (err.error.code) {
              this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
            } else {
              this.checkDuplicateErrorMessage('Error', err.error.message);
            }
          });
      }
    } else {
      const dropdownsEmpty: string[] = [];
      if (!this.clientFormData.userId) {
        dropdownsEmpty.push('Técnico');
      }
      this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false, dropdownsEmpty); // this.clientFormId?.form.controls['phone'].setErrors({ required: true });
      this.clientForm.forEach((item: any) => {
        if (item.required) {
          this.clientFormId?.form.controls[item.key].markAsDirty();
        }
      });
    }
  };

  setClientDataInQuotation(id: string) {
    const quotationData = JSON.parse(localStorage.getItem('quotationData') as string);
    localStorage.removeItem('quotationData');
    quotationData.clientId = id;
    localStorage.setItem('quotationData', JSON.stringify(quotationData));
  }

  createClientFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.clientFormData, { ...(evt as ClientForm) });
    }
  }

  createClientFormISValid(evt: boolean) {
    this.isClientFormValid = evt;
  }

  cancelCreation = () => {
    if (localStorage.getItem('quotationData')) {
      this.router.navigate(['/private/quotations/new']);
    } else {
      this.router.navigate(['/private/clients']);
    }
  };

  // Get technicians
  async getTechnicians() {
    const technicians = await this.technicianService.getAllTechnicians({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });
    this.clientForm[5].selectOptions = technicians.data.map(technician => {
      return {
        label: technician.name || '',
        value: technician.id,
      };
    });
  }

  //Error Messages
  checkDuplicateErrorMessage(
    title: string,
    message: string,
    getFormErrors?: boolean,
    getBackendErrors?: boolean,
    dropdownsEmpty?: string[],
  ) {
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors(dropdownsEmpty);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors(extraFields?: string[]) {
    const error: string[] = [];
    this.clientForm.forEach(element => {
      const isInvalid = (this.clientFormId as any).getFormValidationErrorsByKey(element.key);
      if (isInvalid.length > 0) {
        error.push(element.label);
      }
    });
    if (extraFields) {
      extraFields.forEach(e => {
        error.push(e);
      });
    }
    return error.join(', ');
  }
}

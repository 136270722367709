<div class="content-wrapper">
    <div class="header-wrapper">
        <h1 class="text-primary"> Clientes </h1>
        <prt-button *ngIf="userCanCreate" color="secondary" [italic]="true" [callback]="addClient">
            <div class="button-box">
                <img class="button-icon" src="../../../assets/icons/add-icon.svg" alt="add">
                <h5 class="add-label">Agregar nuevo</h5>
            </div>
        </prt-button>
    </div>
    <div class="table-external-wrapper">
        <prt-table 
            [rows]="clientsTable" 
            [columns]="tableColumns" 
            [icons]="tableIcons" 
            [actions]="tableActions"
            [pagination]="tablePagination" 
            (changeCurrentPage)="handleChangePage($event)"
            [resultsLabel]="'Clientes'" 
            [isLoading]="!isFetchingData"
            [showLoading]= true
            [isExporting]="isExporting"
            (onSorting)="onSortTable($event)"
            [hasSearch]="true" 
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)" 
            [filterActionLabel]="filterLabel"
            [actionsTooltips]="actionsTooltips"
            [showActionsAtStart]="true"
            [staticRowActions]="true"
            columnMode="force"
            [exportDataConfig]="exportDataConfig">
        </prt-table>
    </div>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal" (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar este cliente?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button [isInverted]="true" type="outline" [callback]="cancelDeletion">
                    Cancelar
                </prt-button>
                <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>
    </prt-modal>
</div>
<div class="content-wrapper">
    
    <div class="header-with-tabs">
        <div class="header-wrapper">
            <h1 class="text-primary"> {{tabSelected === 'PRODUCTS' ? 'Productos' : 'Categorías'}} </h1>
            <!-- <prt-button *ngIf="userCanCreateProducts" color="secondary" [italic]="true" [callback]="addProduct">
                <div class="button-box">
                    <img class="button-icon" src="../../../assets/icons/add-icon.svg" alt="add">
                    <h5 class="add-label">Agregar</h5>
                </div>
            </prt-button> -->
        </div>
        <div class="tab-menu-wrapper">
            <div class="tab-option" *ngFor="let tabOption of tabMenuOptions" [ngClass]="{'selected': tabSelected === tabOption.key}"
            (click)="tabSelected = tabOption.key; cleanSearch(tabOption.key); setExportationDataConfig(tabOption.key)">
                <span>{{tabOption.label}}</span>
            </div>
        </div>
    </div>

    <!-- Products View -->
    <div class="table-external-wrapper" *ngIf="tabSelected === 'PRODUCTS'">
        <prt-table [rows]="productsTable" [columns]="productsTableColumns" [icons]="productsTableIcons" [actions]="productsTableActions"
            [pagination]="productsTablePagination" (changeCurrentPage)="handleProductsChangePage($event)"
            [resultsLabel]="'Productos'" [isLoading]="!isFetchingData"
            [showLoading]= true
            [isExporting]="isExporting"
            (onSorting)="onSortProductsTable($event)"
            [hasSearch]="true" 
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)" 
            [filterActionLabel]="filterLabel"
            [exportDataConfig]="productExportDataConfig"
            [staticRowActions]="true"
            >
        </prt-table>
    </div>

    <!-- Categories View -->

    <div class="table-external-wrapper" *ngIf="tabSelected === 'CATEGORIES'">
        <prt-table [rows]="categoriesTable" [columns]="categoriesTableColumns" [icons]="categoriesTableIcons" [actions]="categoriesTableActions"
            [pagination]="categoriesTablePagination" (changeCurrentPage)="handleCategoriesChangePage($event)"
            [resultsLabel]="'Categorías'" [isLoading]="!isCategoriesFetchingData"
            [showLoading]= true
            [isExporting]="isExportingCategories"
            (onSorting)="onSortCategoriesTable($event)"
            [hasSearch]="true" 
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)" 
            [filterActionLabel]="filterLabel"
            [exportDataConfig]="categoryExportDataConfig"
            [staticRowActions]="true">
        </prt-table>
    </div>

    <!-- Modals -->

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal" (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar este producto?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelDeletion">
                    Cancelar
                </prt-button>
                <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>
    </prt-modal>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showCategoryDeletionModal" 
    (onAction)="onDeleteCategoryModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar esta categoría?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button [isInverted]="true" type="outline" [callback]="cancelCategoryDeletion">
                    Cancelar
                </prt-button>
                <prt-button [isInverted]="true" type="solid" [callback]="confirmCategoryDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>
    </prt-modal>
</div>
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { AuthService } from '../services';
import { User } from '../models/auth';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<User | boolean> {
  constructor(private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User | boolean> {
    return this.auth.getMe().pipe(
      map(data => {
        if (data) {
          return data;
        } else {
          this.auth.logout();
          return false;
        }
      }),
      catchError(error => {
        console.error(error);
        this.auth.logout();
        return EMPTY;
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { Category, CategoryForm } from 'src/app/models/categories';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private apiService: ApiService) {}

  getAllCategories(query?: object): Promise<BaseRequest<Category>> {
    return this.apiService.get<BaseRequest<Category>>(endpoints.CATEGORIES, query);
  }

  getCategoryById(id: string): Promise<Category> {
    return this.apiService.get<Category>(`${endpoints.CATEGORIES}/${id}`);
  }

  updateCategory(product: Partial<Category>): Promise<Category> {
    const { id } = product;
    delete product.id;
    return this.apiService.put<Category>(`${endpoints.CATEGORIES}/${id}`, product);
  }

  deleteCategory(id: string): Promise<Category> {
    return this.apiService.delete<Category>(`${endpoints.CATEGORIES}/${id}`);
  }

  createCategory(values: CategoryForm) {
    return this.apiService.post(endpoints.CATEGORIES, values);
  }
}

import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Category } from 'src/app/models/categories';
import { Client } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { Product } from 'src/app/models/products';
import { Quotation, QuotationForm, QuotationProduct, QuotationProductForm } from 'src/app/models/quotation';
import { AuthService } from 'src/app/services';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import validation from 'src/app/utils/validation';

@Component({
  selector: 'app-quotations-create',
  templateUrl: './quotations-create.component.html',
  styleUrls: ['./quotations-create.component.scss'],
})
export class QuotationsCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('formTemplate', { static: false }) quotationsFormId: An507FormRefInterface | undefined;
  @ViewChild('productFormTemplate', { static: false }) productsFormId: An507FormRefInterface | undefined;
  @ViewChild('allProductsFormTemplate', { static: false }) allProductsFormId: An507FormRefInterface | undefined;

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;
  clients: Client[] = [];

  quotationForm: TextBoxField[] | DropdownField[] = [
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'userId',
      label: 'Técnico',
      width: 'md',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Técnico inválido',
      showErrorsUx: true,
      placeholder: 'Seleccione un técnico',
    }),
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'clientId',
      label: 'Cliente',
      width: 'md',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Cliente inválido',
      showErrorsUx: true,
      fetchTermAction: term => this.handleChangeTerm({ term }),
      placeholder: 'Seleccione un cliente',
    }),
    // new BasicFormBase({
    //   controlType: 'dropdownWithSearch',
    //   key: 'status',
    //   label: 'Estatus',
    //   width: 'sm',
    //   required: true,
    //   selectOptions: [
    //     {
    //       label: 'Borrador',
    //       value: 'CREATED',
    //     },
    //     {
    //       label: 'Enviada',
    //       value: 'SENT',
    //     },
    //     {
    //       label: 'Aceptada',
    //       value: 'ACCEPTED',
    //     },
    //     {
    //       label: 'Pagada',
    //       value: 'PAID',
    //     },
    //     {
    //       label: 'Cancelada',
    //       value: 'CANCELED',
    //     },
    //   ],
    //   validationErrorMessage: 'Estatus inválido',
    //   showErrorsUx: true,
    //   placeholder: 'Seleccione un estatus',
    // }),
    {
      controlType: 'textBox',
      key: 'note',
      type: 'text',
      value: '',
      label: 'Comentarios',
      required: false,
      width: 'xl',
      validationErrorMessage: 'Comentario inválido',
      maxLength: 250,
      showErrorsUx: true,
      placeholder: 'Escriba comentarios sobre la cotización (opcional)',
    },
  ];

  isQuotationFormValid = false;

  quotationFormData: QuotationForm = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clientId: null,
    note: '',
    products: [],
  };

  isFormLoaded = false;
  quotationId: string | null = null;

  quotationValues: Quotation = {
    createdAt: new Date(),
    id: '',
    note: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clientId: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: null,
    products: [
      {
        detail: '',
        name: '',
        productId: '',
        quantity: 0,
        totalPrice: 0,
        unitPrice: 0,
      },
    ],
    updatedAt: new Date(),
    Client: {
      name: '',
      number: 0,
    },
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    profits: 0,
    saleOrderId: '',
    status: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    showDetail: false,
  };

  //Products table
  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
  };

  tableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editProduct(row),
    deleteAction: (row: Record<string, unknown>) => this.deleteProduct(row['id'] as string),
  };

  productsTable: any[] = [];
  showDeletionModal = false;
  isFetchingData = true;
  productIdToDelete: string | null = null;
  productsSelected: QuotationProductForm[] = [];
  showProductsTable = false;
  actualStatus = 'CREATED';

  tableColumns = [
    {
      prop: 'name',
      name: 'Producto',
      sortable: false,
    },
    {
      prop: 'quantity',
      name: 'Cantidad',
      sortable: false,
      columnAlign: 'center',
    },
    {
      prop: 'productPrice',
      name: 'Precio Compra Socio',
      sortable: false,
      columnAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
    },
    {
      prop: 'unitPrice',
      name: 'Precio Final Cliente',
      sortable: false,
      columnAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
    },
    {
      prop: 'totalPrice',
      name: 'Precio Total',
      columnAlign: 'center',
      sortable: false,
    },
    {
      prop: 'delete',
      name: '',
      sortable: false,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  allProductsTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  //Product Categories
  productCategories: Category[] = [];
  productCategoriesLoaded = false;

  //Product Addition Modal
  showProductFormModal = false;
  //All Products Modal
  showAllProductsModal = false;
  fetchingPrice = false;
  settingTotalPrice = false;
  allProductsIsLoading = false;
  currentCategoryId: string | undefined = undefined;

  allProductsForm: TextBoxField[] | DropdownField[] = [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'productId',
      label: 'Producto',
      width: 'xl',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Producto inválido',
      showErrorsUx: true,
      placeholder: 'Buscar producto',
      fetchTermAction: term => this.handleChangeAllProductsTerm({ term }),
      fetchMoreAction: () => this.handleFetchMoreAllProducts(this.allProductsTablePagination.page + 1),
      value: null,
    }),
    {
      controlType: 'textBox',
      key: 'quantity',
      type: 'number',
      value: '',
      label: 'Cantidad',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroPattern,
      validationErrorMessage: 'Cantidad inválida',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'productPrice',
      type: 'text',
      value: '',
      label: 'Precio Compra Socio',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'unitPrice',
      type: 'text',
      value: '',
      label: 'Precio Final Cliente',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'totalPrice',
      type: 'text',
      value: '',
      label: 'Precio Total',
      required: false,
      width: 'sm',
    },
  ];

  productForm: TextBoxField[] | DropdownField[] = [
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'productId',
      label: 'Producto',
      width: 'xl',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Técnico inválido',
      showErrorsUx: true,
      placeholder: 'Buscar producto',
    }),
    {
      controlType: 'textBox',
      key: 'quantity',
      type: 'number',
      value: '',
      label: 'Cantidad',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroPattern,
      validationErrorMessage: 'Cantidad inválida',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'productPrice',
      type: 'text',
      value: '',
      label: 'Precio Compra Socio',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'unitPrice',
      type: 'text',
      value: '',
      label: 'Precio Final Cliente',
      required: false,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'totalPrice',
      type: 'text',
      value: '',
      label: 'Precio Total',
      required: false,
      width: 'sm',
    },
  ];

  isProductFormValid = false;

  productFormData: QuotationProductForm = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    productId: null,
    name: '',
    quantity: 0,
    productPrice: 0,
    unitPrice: 0,
    totalPrice: 0,
  };

  isProductFormLoaded = false;
  productValues: QuotationProduct = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    productId: null,
    name: '',
    quantity: 0,
    unitPrice: 0,
    totalPrice: 0,
    detail: '',
    product: {
      active: false,
      categoryId: '',
      Category: {
        name: '',
      },
      id: '',
      name: '',
      picUrl: '',
      price: 0,
      sku: '',
      syncId: '',
      unit: '',
      zohoId: '',
    },
  };

  quotationTotalPrice: number | string = 0;

  doNotAutosave = false;
  isAutoSaving = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private quotationService: QuotationsService,
    private technicianService: TechniciansService,
    private clientService: ClientsService,
    private productService: ProductsService,
    private productCategoriesService: CategoriesService,
    private authService: AuthService,
    private currencyPipe: CurrencyPipe,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    const me = await firstValueFrom(this.authService.getMe());
    try {
      if (this.technicianService.isTechnician(me.role.name)) {
        this.quotationForm = this.quotationForm.filter(i => i.key != 'userId');
        this.quotationsFormId?.form.controls['userId'].setValue(me.id, { emitEvent: true });
        this.getClients();
      } else {
        this.getTechnicians();
        this.getClients();
      }
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }

    this.getProductCategories();

    this.route.queryParamMap.subscribe(async params => {
      this.quotationId = params.get('quotationId');
      if (this.quotationId) {
        this.isFormLoaded = false;
        this.quotationValues = await this.quotationService.getQuotationById(this.quotationId);
        this.quotationTotalPrice = this.quotationValues.total;

        // if (this.quotationValues.status === 'SENT') {
        //   this.quotationForm[2].selectOptions?.splice(0, 1);
        // }

        this.actualStatus = this.quotationValues.status;

        setTimeout(() => {
          this.fillForm();
          this.isFormLoaded = true;
          this.canUpdate();
        });

        if (this.quotationValues.products) {
          this.productsSelected = this.quotationValues.products.map((e: any) => e);
          this.fillProductsTable();
        }
      } else {
        this.quotationsFormId?.updateForm({
          status: 'CREATED',
          userId: this.technicianService.isTechnician(me.role.name) ? me.id : null,
          clientId: null,
        });
        // this.quotationsFormId?.form.controls['status'].disable();
        this.quotationForm = this.quotationForm.filter(i => i.key != 'status');
        if (localStorage.getItem('quotationData')) {
          this.quotationValues = JSON.parse(localStorage.getItem('quotationData') as string);
          this.fillForm();

          if (this.quotationValues.products) {
            this.productsSelected = this.quotationValues.products.map((e: any) => e);
            this.fillProductsTable();
          }

          localStorage.removeItem('quotationData');
        }
        this.isFormLoaded = true;
      }
    });
  }

  ngAfterViewInit() {
    this.allProductsForm[0].triggerOnBlur = this.resetAllProductsSearchOnBlur;
  }

  resetAllProductsSearchOnBlur = async () => {
    this.allProductsTablePagination = {
      page: 1,
      limit: 10,
      count: 0,
    };

    const categoryProducts = await this.productService.getAllProducts({
      limit: this.allProductsTablePagination.limit,
      type: 'GOODS',
      sortKey: 'name',
      sortOrder: 'asc',
    });

    this.allProductsTablePagination.count = categoryProducts.count;

    this.allProductsForm[0].selectOptions = categoryProducts.data.map(product => {
      const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
      return {
        label: product.name || '',
        value: alreadySelected ? '-1' : product.id,
      };
    });
  };

  async fillForm() {
    this.quotationsFormId?.updateForm({
      note: this.quotationValues.note,
      clientId: this.quotationValues.clientId,
      userId: this.quotationValues.userId,
      status: this.quotationValues.status,
    });
  }

  // Get technicians
  async getTechnicians() {
    const technicians = await this.technicianService.getAllTechnicians({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });
    this.quotationForm[0].selectOptions = technicians.data.map(technician => {
      return {
        label: technician.name || '',
        value: technician.id,
      };
    });
  }

  // Get clients
  async getClients() {
    //let clients: any;
    const isTechnician = this.technicianService.isTechnician(this.authService.user?.role.name || '');

    if (isTechnician) {
      this.clients = (
        await this.clientService.getAllClients({
          limit: 0,
          sortKey: 'name',
          sortOrder: 'asc',
        })
      ).data;
    } else {
      this.clients = (
        await this.clientService.getAllClients({
          limit: 0,
          sortKey: 'name',
          sortOrder: 'asc',
        })
      ).data;
    }
    this.quotationForm[isTechnician ? 0 : 1].selectOptions = this.clients.map((client: Client) => {
      return {
        label: client.name || '',
        value: client.id,
      };
    });
    this.quotationForm[isTechnician ? 0 : 1].selectOptions?.push({ label: 'Agregar nuevo cliente', value: '-1' });
    //this.quotationForm[1].selectOptions?.unshift({ label: 'Agregar nuevo cliente', value: '0' });
  }

  // Get product categories
  async getProductCategories() {
    const productCategories = await this.productCategoriesService.getAllCategories({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });
    const defaultCategory: Category = {
      id: 'all',
      name: 'TODOS LOS PRODUCTOS',
      createdAt: new Date(),
      updatedAt: new Date(),
      picUrl: '',
      syncId: '',
      zohoId: '',
    };
    this.productCategories = [defaultCategory, ...productCategories.data];
    this.productCategoriesLoaded = true;
  }

  handleChangeTerm({ term }: { term: string }) {
    const filteredClients = this.clients.filter((cur: Client) =>
      cur.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
    const isTechnician = this.technicianService.isTechnician(this.authService.user?.role.name || '');
    if (!filteredClients.length) {
      this.quotationForm[isTechnician ? 0 : 1].selectOptions = [{ label: 'Agregar nuevo cliente', value: '-1' }];
    } else {
      this.quotationForm[isTechnician ? 0 : 1].selectOptions = filteredClients.map((cur: Client) => {
        return {
          label: cur.name || '',
          value: cur.id,
        };
      });
      this.quotationForm[isTechnician ? 0 : 1].selectOptions?.push({ label: 'Agregar nuevo cliente', value: '-1' });
    }
  }

  async handleChangeAllProductsTerm({ term }: { term: string }) {
    this.allProductsIsLoading = true;
    this.allProductsTablePagination = {
      page: 1,
      limit: 10,
      count: 0,
    };

    const categoryProducts = await this.productService.getAllProducts({
      limit: this.allProductsTablePagination.limit,
      type: 'GOODS',
      sortKey: 'name',
      sortOrder: 'asc',
      q: term,
    });
    this.allProductsTablePagination.count = categoryProducts.count;
    this.allProductsForm[0].selectOptions = categoryProducts.data.map(product => {
      const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
      return {
        label: product.name || '',
        value: alreadySelected ? '-1' : product.id,
      };
    });
    this.allProductsIsLoading = false;
  }

  handleFetchMoreAllProducts(page: number) {
    if (
      this.allProductsForm[0].selectOptions &&
      this.allProductsTablePagination.count <= this.allProductsForm[0].selectOptions.length
    ) {
      return;
    }
    this.allProductsIsLoading = true;
    this.allProductsTablePagination = {
      ...this.allProductsTablePagination,
      page,
    };

    this.productService
      .getAllProducts({
        limit: this.allProductsTablePagination.limit,
        type: 'GOODS',
        sortKey: 'name',
        sortOrder: 'asc',
        q: this.allProductsForm[0].value as string,
        page: page.toString(),
      })
      .then(categoryProducts => {
        this.allProductsTablePagination.count = categoryProducts.count;
        if (this.allProductsForm[0].selectOptions) {
          this.allProductsForm[0].selectOptions = [
            ...this.allProductsForm[0].selectOptions,
            ...categoryProducts.data.map(product => {
              const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
              return {
                label: product.name || '',
                value: alreadySelected ? '-1' : product.id,
              };
            }),
          ];
        }
      })
      .finally(() => (this.allProductsIsLoading = false));
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Quotation');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Quotation')) {
      this.quotationsFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Create Quotation
  createQuotation = () => {
    this.doNotAutosave = true;
    if (!this.quotationFormData.userId || !this.quotationFormData.clientId) {
      this.quotationForm.forEach((item: any) => {
        if (item.required) {
          this.quotationsFormId?.form.controls[item.key].markAsDirty();
        }
      });
      return this.checkDuplicateErrorMessage(
        'Por favor, complete los campos obligatorios:',
        '',
        true,
        false,
        'QUOTATION',
      );
    }
    if (this.quotationValues?.id && this.isQuotationFormValid) {
      const formData = {
        id: this.quotationValues.id,
        note: this.quotationFormData.note,
        status: this.quotationFormData.status || this.quotationValues.status,
        clientId: this.quotationFormData.clientId,
        userId: this.quotationFormData.userId,
        products: this.setProductsOnForm(),
      };
      if (this.quotationValues.status === 'CREATED' && this.quotationFormData.status === 'PAID') {
        this.toastr.error('No es posible cambiar estatus de cotización Borrador a Pagada');
        return;
      }
      if (this.quotationValues.status === 'CREATED' && this.quotationFormData.status === 'ACCEPTED') {
        this.toastr.error('No es posible cambiar estatus de cotización Borrador a Aceptada');
        return;
      }
      if (this.quotationValues.status === 'SENT' && this.quotationFormData.status === 'CREATED') {
        this.toastr.error('No es posible cambiar estatus de cotización Enviada a Borrador');
        return;
      }
      if (this.quotationValues.status === 'ACCEPTED' && this.quotationFormData.status === 'CREATED') {
        this.toastr.error('No es posible cambiar estatus de cotización Aceptada a Borrador');
        return;
      }
      this.quotationService
        .updateQuotation(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cotización editada');
          if (formData.status === 'CREATED') {
            this.router.navigate(['/private/quotations/send'], {
              queryParams: { quotationId: this.quotationId || this.quotationValues.id },
            });
          } else if (formData.status === 'SENT' && (res as any).url) {
            if (navigator.share) {
              navigator
                .share({
                  title: 'Compartir Cotización',
                  text: 'Cotización #' + this.quotationValues.number,
                  url: `https://public.${window.location.hostname}/public/quotations/${this.quotationId}`,
                })
                .then(() => {
                  // this.checkDuplicateSuccessMessage('', 'Cotización enviada exitosamente');
                  this.router.navigate(['/private/quotations']);
                })
                .catch(error => {
                  console.error(error);
                  this.router.navigate(['/private/quotations']);
                });
            } else {
              this.router.navigate(['/private/quotations']);
            }
          } else {
            this.router.navigate(['/private/quotations']);
          }
        })
        .catch(err => {
          this.quotationsFormId?.updateForm({
            status: this.actualStatus,
          });
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        })
        .finally(() => (this.doNotAutosave = false));
    } else if (!this.quotationValues?.id && this.isQuotationFormValid) {
      this.quotationFormData.products = this.setProductsOnForm();
      this.quotationService
        .createQuotation(this.quotationFormData)
        .then((res: any) => {
          this.quotationId = res.id;
          this.checkDuplicateSuccessMessage('', 'Cotización creada');
          this.router.navigate(['/private/quotations/send'], { queryParams: { quotationId: this.quotationId } });
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        })
        .finally(() => (this.doNotAutosave = false));
    } else {
      this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false, 'QUOTATION');
      this.quotationForm.forEach((item: any) => {
        if (item.required) {
          this.quotationsFormId?.form.controls[item.key].markAsDirty();
        }
      });
    }
  };

  setProductsOnForm() {
    const products = this.productsSelected
      .filter(e => e !== null)
      .map(e => {
        return {
          id: e.productId,
          quantity: this.checkVariableType(e.quantity),
          unitPrice: this.formattedToNumber(e.unitPrice),
          totalPrice: e.totalPrice,
        };
      });

    return products;
  }

  createQuotationFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      const { clientId } = evt as any;
      if (clientId === '-1') {
        this.addClient();
      }
      if (this.quotationFormData.status !== (evt as any).status && this.isFormLoaded) {
        this.doNotAutosave = true;
      }
      Object.assign(this.quotationFormData, { ...(evt as QuotationForm) });
    }
  }

  createQuotationFormISValid(evt: boolean) {
    this.isQuotationFormValid = evt;
    if (this.quotationValues?.id && this.isQuotationFormValid && this.isFormLoaded) {
      this.autosave();
      this.doNotAutosave = false;
    }
  }

  cancelCreation = () => {
    if (!this.quotationId && this.quotationValues?.id) {
      this.toastr.success('Cotización creada como borrador');
    }
    if (
      this.quotationId &&
      this.quotationModified &&
      this.quotationsFormId?.form.valid &&
      this.productsSelected.length
    ) {
      this.toastr.success('Cotización editada');
    }
    this.router.navigate(['/private/quotations']);
  };

  //Error Messages
  checkDuplicateErrorMessage(
    title: string,
    error: any,
    getFormErrors?: boolean,
    getBackendErrors?: boolean,
    formType?: 'PRODUCT' | 'QUOTATION',
  ) {
    let message = error.code ? error.code : error.message ? error.message : error;
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors(formType);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
      if (error.detail?.inssuficientStock?.length) {
        message += ` ${error.detail?.inssuficientStock[0].name}`;
      }
      if (error.detail?.notAllowedPrice?.length) {
        message += `. Producto: ${error.detail?.notAllowedPrice[0].name}. Precio en inventario: ${error.detail?.notAllowedPrice[0].realPrice}`;
      }
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors(formType?: 'PRODUCT' | 'QUOTATION') {
    const error: string[] = [];

    if (formType === 'QUOTATION') {
      this.quotationForm.forEach(element => {
        const isInvalid = (this.quotationsFormId as any).getFormValidationErrorsByKey(element.key);
        if (isInvalid.length > 0) {
          error.push(element.label);
        }
      });
    } else {
      this.productForm.forEach(element => {
        const isInvalid = (this.quotationsFormId as any).getFormValidationErrorsByKey(element.key);
        if (isInvalid.length > 0) {
          error.push(element.label);
        }
      });
    }
    return error.length ? `${error.join(', ')}` : 'Por favor llene todos los campos';
  }

  //Delete Selected Product
  deleteProduct(productId: string) {
    this.productIdToDelete = productId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.productIdToDelete = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.productIdToDelete && this.productIdToDelete !== '') {
      const indexToDelete = this.productsSelected.findIndex(e => e.productId === this.productIdToDelete);
      this.productsSelected.splice(indexToDelete, 1);
      this.checkDuplicateSuccessMessage('', 'Producto eliminado');
      this.fillProductsTable();
      this.showDeletionModal = false;
      this.productIdToDelete = null;
      this.autosave();
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  //Products Table
  async fillProductsTable() {
    if (this.productsSelected.length > 0) {
      this.showProductsTable = true;
      this.isFetchingData = true;
      this.productsTable = this.productsSelected.map(product => {
        return {
          id: product.productId,
          name: product.name,
          quantity: product.quantity,
          productPrice: product.product
            ? this.currencyPipe.transform(product.product?.price.toString(), ' ')
            : this.currencyPipe.transform(product.productPrice.toString(), ' '),
          unitPrice: this.currencyPipe.transform(this.formattedToNumber(product.unitPrice).toString(), ' '),
          totalPrice: this.currencyPipe.transform(product.totalPrice.toString(), ' '),
        };
      });
      this.tablePagination = {
        page: this.tablePagination.page,
        limit: this.tablePagination.limit,
        count: this.productsSelected.length,
      };
      this.isFetchingData = false;
    } else {
      this.productsTable = [];
      this.showProductsTable = false;
    }
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.fillProductsTable();
  }

  //Quotation Total Price
  calcQuotationTotal() {
    let total = 0;

    this.productsSelected.forEach(product => {
      total = total + product.totalPrice;
    });

    this.quotationTotalPrice = this.currencyPipe.transform(total.toString(), ' ') || 0;
  }

  //Product Modal
  async openProductSelectionModal(categoryId: string) {
    this.currentCategoryId = categoryId;
    let noAlreadySelectedProducts: Product[] = [];
    this.productsFormId?.resetForm();

    const categoryProducts = await this.productService.getAllProducts({
      limit: 0,
      type: 'GOODS',
      categoryId: categoryId,
      sortKey: 'name',
      sortOrder: 'asc',
    });

    if (this.productsSelected.length > 0) {
      noAlreadySelectedProducts = categoryProducts.data.filter(product => {
        const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
        if (alreadySelected) {
          return false;
        } else {
          return product;
        }
      });
    } else {
      noAlreadySelectedProducts = categoryProducts.data;
    }

    if (noAlreadySelectedProducts.length > 0) {
      this.productForm[0].selectOptions = categoryProducts.data.map(product => {
        const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
        return {
          label: product.name || '',
          value: alreadySelected ? '-1' : product.id,
        };
      });

      this.showProductFormModal = true;
      this.productsFormId?.form.controls['productPrice'].disable();
      this.productsFormId?.form.controls['totalPrice'].disable();
      this.productsFormId?.form.controls['unitPrice'].disable();
      this.productsFormId?.form.controls['quantity'].disable();
    } else {
      this.checkDuplicateErrorMessage('', 'Ya seleccionaste todos los productos de esta categoría');
    }
  }

  async openAllProductsSelectionModal() {
    // let noAlreadySelectedProducts: Product[] = [];
    this.currentCategoryId = 'all';
    this.allProductsFormId?.resetForm();
    this.allProductsIsLoading = true;
    this.allProductsTablePagination = {
      page: 1,
      limit: 10,
      count: 0,
    };

    const categoryProducts = await this.productService.getAllProducts({
      limit: this.allProductsTablePagination.limit,
      type: 'GOODS',
      sortKey: 'name',
      sortOrder: 'asc',
    });
    this.allProductsTablePagination.count = categoryProducts.count;
    this.allProductsForm[0].selectOptions = categoryProducts.data.map(product => {
      const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
      return {
        label: product.name || '',
        value: alreadySelected ? '-1' : product.id,
      };
    });

    this.showAllProductsModal = true;
    this.allProductsIsLoading = false;
    this.allProductsFormId?.form.controls['productPrice'].disable();
    this.allProductsFormId?.form.controls['totalPrice'].disable();
    this.allProductsFormId?.form.controls['unitPrice'].disable();
    this.allProductsFormId?.form.controls['quantity'].disable();
  }

  onProductFormModalStatus(evt: { isOpen: boolean }) {
    this.showProductFormModal = evt.isOpen;
  }

  onAllProductsFormModalStatus(evt: { isOpen: boolean }) {
    this.showAllProductsModal = evt.isOpen;
  }

  async addAllProductsFormChanges(evt: object) {
    if (((evt as any).productId === null && this.productFormData.productPrice) || (evt as any).productId === '-1') {
      // Throw product already selected toast
      this.checkDuplicateErrorMessage('', 'Ya seleccionaste este producto');
      this.resetAllProductsData();
      if (!this.allProductsFormId?.form.controls['unitPrice'].disabled) {
        this.allProductsFormId?.form.controls['unitPrice'].disable();
      }
      if (!this.allProductsFormId?.form.controls['quantity'].disabled) {
        this.allProductsFormId?.form.controls['quantity'].disable();
      }
      return;
    }

    if (!this.fetchingPrice && (evt as any).productId && (evt as any).productId !== this.productFormData.productId) {
      if ((evt as any).productId !== this.productFormData.productId) {
        this.setProductData((evt as any).productId)
          .then((productSelected: any) => {
            this.allProductsForm[1].placeholder = productSelected.unit;
            this.allProductsFormId?.updateForm({
              quantity: 1,
              unitPrice: this.currencyPipe.transform((productSelected.price * 1.1).toString(), ' '),
              productPrice: productSelected.price,
            });
            this.productFormData = {
              ...this.productFormData,
              quantity: 1,
              unitPrice: productSelected.price * 1.1,
              productPrice: productSelected.price,
              name: productSelected.name,
            };
            this.allProductsForm[1].required = true;
            this.allProductsForm[2].required = true;
            this.allProductsForm[3].required = true;

            if (this.allProductsFormId?.form.controls['unitPrice'].disabled) {
              this.allProductsFormId?.form.controls['unitPrice'].enable();
            }
            if (this.allProductsFormId?.form.controls['quantity'].disabled) {
              this.allProductsFormId?.form.controls['quantity'].enable();
            }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.fetchingPrice = false;
          });
      }
    }

    if ((evt as any).quantity && (evt as any).productId) {
      if (
        (this.productFormData.quantity !== (evt as any).quantity ||
          this.productFormData.productId !== (evt as any).productId ||
          !this.productFormData.totalPrice ||
          this.productFormData.unitPrice !== (evt as any).unitPrice) &&
        !this.settingTotalPrice
      ) {
        this.setAllTotalPrice((evt as any).quantity, this.formattedToNumber((evt as any).unitPrice));
        Object.assign(this.productFormData, { ...(evt as QuotationProduct) });
      }
    }
  }

  async addProductFormChanges(evt: object) {
    if (((evt as any).productId === null && this.productFormData.productPrice) || (evt as any).productId === '-1') {
      this.checkDuplicateErrorMessage('', 'Ya seleccionaste este producto');
      this.resetProductData();
      if (!this.productsFormId?.form.controls['unitPrice'].disabled) {
        this.productsFormId?.form.controls['unitPrice'].disable();
      }
      if (!this.productsFormId?.form.controls['quantity'].disabled) {
        this.productsFormId?.form.controls['quantity'].disable();
      }
    }

    if (!this.fetchingPrice && (evt as any).productId && (evt as any).productId !== this.productFormData.productId) {
      if ((evt as any).productId !== this.productFormData.productId) {
        this.setProductData((evt as any).productId)
          .then((productSelected: any) => {
            this.productForm[1].placeholder = productSelected.unit;
            this.productsFormId?.updateForm({
              quantity: 1,
              unitPrice: this.currencyPipe.transform((productSelected.price * 1.1).toString(), ' '),
              productPrice: productSelected.price,
            });
            this.productFormData = {
              ...this.productFormData,
              quantity: 1,
              unitPrice: productSelected.price * 1.1,
              productPrice: productSelected.price,
              name: productSelected.name,
            };
            this.productForm[1].required = true;
            this.productForm[2].required = true;
            this.productForm[3].required = true;

            if (this.productsFormId?.form.controls['unitPrice'].disabled) {
              this.productsFormId?.form.controls['unitPrice'].enable();
            }
            if (this.productsFormId?.form.controls['quantity'].disabled) {
              this.productsFormId?.form.controls['quantity'].enable();
            }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.fetchingPrice = false;
          });
      }
    }

    if ((evt as any).quantity && (evt as any).productId) {
      if (
        (this.productFormData.quantity !== (evt as any).quantity ||
          this.productFormData.productId !== (evt as any).productId ||
          !this.productFormData.totalPrice ||
          this.productFormData.unitPrice !== (evt as any).unitPrice) &&
        !this.settingTotalPrice
      ) {
        this.setTotalPrice((evt as any).quantity, this.formattedToNumber((evt as any).unitPrice));
        Object.assign(this.productFormData, { ...(evt as QuotationProduct) });
      }
    }
  }

  private formattedToNumber(price: any) {
    if (typeof price === 'string') {
      const precioSinComas = price.replace(/,/g, '');
      return parseFloat(precioSinComas);
    } else {
      return price;
    }
  }

  addProductFormISValid(evt: boolean) {
    this.isProductFormValid = evt;
  }

  cancelProductAddition = () => {
    this.showProductFormModal = false;
    this.productForm[1].placeholder = '';
    this.productsFormId?.resetForm();
  };

  cancelAllProductsAddition = () => {
    this.showAllProductsModal = false;
    this.resetAllProductsData();
  };

  addProduct = () => {
    if (this.checkIfProductIsDuplicate(this.productFormData?.productId)) {
      return;
    }
    setTimeout(() => {
      // wait for form changes
      if (this.isProductFormValid) {
        const productData = JSON.parse(JSON.stringify(this.productFormData));
        if (this.formattedToNumber(productData.unitPrice) < productData.productPrice) {
          this.checkDuplicateErrorMessage(
            '',
            'El precio final del producto no puede ser menor al precio del inventario',
          );
          return;
        }
        this.productsSelected.push(productData);
        this.showProductFormModal = false;
        this.resetProductData();
        this.fillProductsTable();
        this.calcQuotationTotal();
        this.autosave();
      } else {
        this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false, 'PRODUCT');
        this.productForm.forEach((item: any) => {
          if (item.required) {
            this.productsFormId?.form.controls[item.key].markAsDirty();
          }
        });
      }
    }, 200);
  };

  checkIfProductIsDuplicate(productId: string | undefined) {
    if (this.productsSelected.length > 0 && productId !== undefined) {
      if (this.productsSelected.some(e => e.productId === productId) || productId === '-1' || productId === null) {
        this.checkDuplicateErrorMessage('', 'Ya seleccionaste este producto');
        return true;
      }
    }
    return false;
  }

  addAllProducts = () => {
    if (this.checkIfProductIsDuplicate(this.productFormData?.productId)) {
      return;
    }
    setTimeout(() => {
      // wait for form changes
      if (this.isProductFormValid) {
        const productData = JSON.parse(JSON.stringify(this.productFormData));
        if (this.formattedToNumber(productData.unitPrice) < productData.productPrice) {
          this.checkDuplicateErrorMessage(
            '',
            'El precio final del producto no puede ser menor al precio del inventario',
          );
          return;
        }
        this.productsSelected.push(productData);
        this.showAllProductsModal = false;
        this.resetAllProductsData();
        this.fillProductsTable();
        this.calcQuotationTotal();
        this.autosave();
      } else {
        this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false, 'PRODUCT');
        this.allProductsForm.forEach((item: any) => {
          if (item.required) {
            this.productsFormId?.form.controls[item.key].markAsDirty();
          }
        });
      }
    }, 200);
  };

  resetProductData() {
    this.productForm[1].placeholder = '';
    this.productsFormId?.updateForm({
      productId: '',
      quantity: null,
      productPrice: null,
      unitPrice: null,
      totalPrice: null,
    });
    this.productFormData = {
      productId: '',
      name: '',
      quantity: 0,
      productPrice: 0,
      unitPrice: 0,
      totalPrice: 0,
    };
    this.productForm[1].required = false;
    this.productForm[2].required = false;
    this.productForm[3].required = false;
  }

  resetAllProductsData() {
    this.allProductsForm[1].placeholder = '';
    this.allProductsFormId?.updateForm({
      productId: '',
      quantity: null,
      productPrice: null,
      unitPrice: null,
      totalPrice: null,
    });
    this.productFormData = {
      productId: '',
      name: '',
      quantity: 0,
      productPrice: 0,
      unitPrice: 0,
      totalPrice: 0,
    };
    this.allProductsForm[1].required = false;
    this.allProductsForm[2].required = false;
    this.allProductsForm[3].required = false;
  }

  editProduct(product: any) {
    this.isProductFormLoaded = false;
    this.showProductFormModal = true;
    this.productValues = product;
    this.productValues.productId = product.id;

    setTimeout(() => {
      this.fillProductForm();
      this.isProductFormLoaded = true;
    });
  }

  async fillProductForm() {
    this.productsFormId?.updateForm({
      productId: this.productValues.productId,
      quantity: this.productValues.quantity,
      productPrice: this.productValues.product?.price,
      unitPrice: this.productValues.unitPrice,
      totalPrice: this.productValues.totalPrice,
    });
  }

  async setProductData(productId: string) {
    this.fetchingPrice = true;

    const promise = new Promise(async (res, rej) => {
      let productSelected: any;
      try {
        productSelected = await this.productService.getProductById(productId);
        res(productSelected);
      } catch (error) {
        console.error(error);
        rej(error);
      }
    });

    return promise;
  }

  setTotalPrice(quantity: any, unitPrice: number) {
    this.settingTotalPrice = true;
    const totalPrice = this.checkVariableType(quantity) * (unitPrice ? unitPrice : this.productFormData.unitPrice);
    const totalPriceFormatted = this.currencyPipe.transform(totalPrice.toString(), ' ');

    this.productsFormId?.updateForm({
      totalPrice: totalPriceFormatted,
    });

    this.productFormData.totalPrice = totalPrice;

    this.settingTotalPrice = false;
  }

  setAllTotalPrice(quantity: any, unitPrice: number) {
    this.settingTotalPrice = true;
    const totalPrice = this.checkVariableType(quantity) * (unitPrice ? unitPrice : this.productFormData.unitPrice);
    const totalPriceFormatted = this.currencyPipe.transform(totalPrice.toString(), ' ');

    this.allProductsFormId?.updateForm({
      totalPrice: totalPriceFormatted,
    });

    this.productFormData.totalPrice = totalPrice;

    this.settingTotalPrice = false;
  }

  checkVariableType(value: string | number) {
    return typeof value === 'string' ? Number(value) : value;
  }

  addClient = () => {
    if (this.productsSelected.length > 0) {
      this.quotationFormData.products = this.productsSelected;
    }

    localStorage.setItem('quotationData', JSON.stringify(this.quotationFormData));
    this.router.navigate(['/private/clients/new']);
  };
  quotationModified = false;

  autosave = () => {
    if (this.doNotAutosave) {
      this.doNotAutosave = false;
      return;
    }
    if (!this.productsSelected.length) {
      this.checkDuplicateErrorMessage('', 'No se puede guardar una cotización sin productos');
      return;
    }
    if (this.quotationValues?.id && this.isQuotationFormValid) {
      this.isAutoSaving = true;
      const formData = {
        id: this.quotationValues.id,
        note: this.quotationFormData.note,
        clientId: this.quotationFormData.clientId,
        userId: this.quotationFormData.userId,
        products: this.setProductsOnForm(),
      };
      this.quotationService
        .updateQuotation(formData)
        .then(res => {
          // this.checkDuplicateSuccessMessage('', 'Cotización editada');
          if (!this.quotationModified) {
            this.quotationModified = true;
          }
        })
        .catch(err => {
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        })
        .finally(() => (this.isAutoSaving = false));
    } else if (!this.quotationValues?.id && this.isQuotationFormValid) {
      this.quotationFormData.products = this.setProductsOnForm();
      this.quotationService
        .createQuotation(this.quotationFormData)
        .then((res: any) => {
          this.quotationValues.id = res.id;
          this.quotationValues.status = res.status;
          this.isQuotationFormValid = true;
          this.doNotAutosave = false;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        })
        .finally(() => (this.isAutoSaving = false));
    }
  };

  get getCurrentCategoryName() {
    return this.productCategories.find(e => e.id === this.currentCategoryId)?.name || '';
  }
}

import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, AfterViewInit, OnInit } from '@angular/core';

@Directive({
  selector: '[appDynamicScript]',
})
export class DynamicScriptDirective implements OnInit {
  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    //
  }

  ngAfterViewInit() {
    const templateEl = this.el.nativeElement.firstElementChild as HTMLElement;
    if (templateEl) {
      this.replaceDivWithScript(templateEl);
    }
  }

  private replaceDivWithScript(templateEl: HTMLElement) {
    const script = this.document.createElement('script');
    const scriptConfig = this.document.createElement('script');
    script.setAttribute('src', templateEl.textContent as string);
    scriptConfig.appendChild(this.document.createTextNode(' var wpwlOptions = { locale: "es"}'));
    templateEl.remove();
    this.el.nativeElement.appendChild(scriptConfig);
    this.el.nativeElement.appendChild(script);
  }
}

import { RoleDetailReport } from './roleDetailReport';
export interface Technician {
  id: string;
  email: string;
  name: string;
  number: string;
  code?: string;
  address: string;
  picUrl?: string;
  dni: string;
  phone: string;
  phoneCode: string;
  countryCode: string;
  company: string;
  roleId: string;
  createdAt: Date;
  updatedAt: Date;
  quotationsCount?: string;
  salesCount?: string;
  lastLogin?: string;
  role: RoleDetailReport;
  password?: string;
  pic?: Blob | string;
}

export interface TechnicianForm {
  number: string;
  dialCode: string;
  email: string;
  password: string;
  name: string;
  code?: string;
  address?: string;
  dni?: string;
  phone?: {
    dialCode?: string;
    number?: string;
    countryCode?: string;
  };
  company?: string;
  pic?: Blob | string;
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { Ability } from '@casl/ability';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  currentLocation = window.location;
  layoutSideNavData: any = {
    sideNavHeader: {
      logo: {
        img: '../../../assets/dorita-side-nav-logo.png',
        callback: () => {
          this.navigateTo('/private/home');
        },
      },
      user: {
        name: '',
        // img: '../../../assets/icons/profile-icon.svg',
        callback: function () {
          alert('Profile');
        },
      },
    },
    sideNavActions: [],
    sideNavFooter: {
      logout: {
        img: '../../../assets/icons/logout-icon.svg',
        callback: () => {
          this.showModal = true;
        },
        name: 'Salir',
      },
    },
  };

  isPhone = false;
  isTechnician = false;

  navBarOptions: any[] = [];
  navBarOptionsLoaded = false;

  activeNavBarOption = 0;
  isLoading = true;
  useLegacyIcons = false;
  isQuotationSend = false;
  showModal = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private ability: Ability,
    private technicianService: TechniciansService,
    private http: HttpClient,
  ) {
    if (window.innerWidth <= 767) {
      this.isPhone = true;
    }
    this.userIsTechnician();
    this.checkUrl();
  }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.checkUrl();
      }
    });
  }

  checkUrl() {
    if (this.router.url.includes('quotations')) {
      this.activeNavBarOption = 1;
    } else if (this.router.url.includes('clients')) {
      this.activeNavBarOption = 0;
    } else if (this.router.url.includes('profile')) {
      this.activeNavBarOption = 2;
    }
  }

  checkIsQuotationSend() {
    return this.router.url.includes('quotations/send');
  }

  async userIsTechnician() {
    const me = await firstValueFrom(this.auth.getMe());
    this.isTechnician = this.technicianService.isTechnician(me.role.name);
    this.createMenu();
    this.isLoading = false;
  }

  async selectOption(index: number, path: string, params?: { paramLabel: string; paramValue: string }) {
    this.activeNavBarOption = index;
    this.navigateTo(path, params);
  }

  createMenu() {
    if (this.isPhone && this.isTechnician) {
      if (this.ability.can('read', 'Client')) {
        this.addPhoneNavBarAction({
          label: 'Clientes',
          img: 'clients-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/clients'),
          route: {
            path: '/private/clients',
            params: '',
          },
        });
      }
      if (this.ability.can('read', 'Quotation')) {
        this.addPhoneNavBarAction({
          label: 'Cotizaciones',
          img: 'quotations-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/quotations'),
          route: {
            path: '/private/quotations',
            params: '',
          },
        });
      }

      this.addPhoneNavBarAction({
        label: 'Perfil',
        img: 'user-icon.svg',
        isActive: this.currentLocation.pathname.includes('/private/profile'),
        route: {
          path: '/private/profile',
          params: { paramLabel: 'technicianId', paramValue: `${this.auth.user?.id}` },
        },
      });
      this.navBarOptionsLoaded = true;
      if (this.navBarOptionsLoaded && this.activeNavBarOption === 0) {
        this.activeNavBarOption = -1;
      }
    } else {
      this.layoutSideNavData.sideNavHeader = {
        ...this.layoutSideNavData.sideNavHeader,
        user: {
          ...this.layoutSideNavData.sideNavHeader.user,
          name: `${this.auth.user?.name}`,
        },
      };
      if (this.ability.can('read', 'User')) {
        this.addSideNavAction({
          label: 'Administrador',
          img: 'administrator-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/admin/users'),
          callback: () => {
            this.navigateTo('/private/admin/users');
          },
        });
      }
      if (this.ability.can('read', 'Quotation')) {
        this.addSideNavAction({
          label: 'Cotizaciones',
          img: 'quotations-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/quotations'),
          callback: () => {
            this.navigateTo('/private/quotations');
          },
        });
      }
      if (this.ability.can('read', 'Technician')) {
        this.addSideNavAction({
          label: 'Técnicos',
          img: 'technicians-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/technicians'),
          callback: () => {
            this.navigateTo('/private/technicians');
          },
        });
      }
      if (this.ability.can('read', 'Client')) {
        this.addSideNavAction({
          label: 'Clientes',
          img: 'clients-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/clients'),
          callback: () => {
            this.navigateTo('/private/clients');
          },
        });
      }
      if (this.ability.can('read', 'Product')) {
        this.addSideNavAction({
          label: 'Productos',
          img: 'products-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/products'),
          callback: () => {
            this.navigateTo('/private/products');
          },
        });
      }
      if (this.ability.can('read', 'Role')) {
        this.addSideNavAction({
          label: 'Roles',
          img: 'roles-icon.svg',
          isActive: this.currentLocation.pathname.includes('/private/admin/roles'),
          callback: () => {
            this.navigateTo('/private/admin/roles');
          },
        });
      }
    }
    setTimeout(() => this.getSVGFromAssets(), 100);
  }

  addSideNavAction({ label, img, isActive, callback, sublist }: any): void {
    const action: any = {
      label,
      img: `../../../assets/icons/${img}`,
      isActive,
      callback,
    };
    const actionsublist = [];
    if (sublist && sublist?.length > 0) {
      for (let i = 0; i < sublist.length; i++) {
        actionsublist.push(this.buildsublistItem(sublist[i]));
      }
      action['sublist'] = actionsublist;
    }
    this.layoutSideNavData.sideNavActions.push(action);
  }

  buildsublistItem({ label, isActive, callback }: any): object {
    return {
      label,
      isActive,
      callback,
    };
  }

  navigateTo(path: string, params?: { paramLabel: string; paramValue: string }): void {
    if (params) {
      this.router.navigate([`${path}`], { queryParams: { technicianId: `${params.paramValue}` } });
    } else {
      this.router.navigate([`${path}`]);
    }
  }

  addPhoneNavBarAction({ label, img, isActive, route }: any): void {
    const action: any = {
      label,
      img: `../../../assets/icons/${img}`,
      isActive,
      route,
    };
    this.navBarOptions.push(action);
  }

  getSVGFromAssets() {
    this.navBarOptions.forEach((action, i) => {
      const element = document.getElementById('svg#' + (i + 1));
      lastValueFrom(this.http.get(action.img, { responseType: 'text' }))
        .then(svg => {
          let svgString = svg;
          if (element) {
            svgString = svg.replace(/fill="none"/g, 'fill="inherit"');
            svgString = svgString.replace(/fill="#0070B3"/g, 'fill="inherit"');
            element.innerHTML = svgString;
          }
        })
        .catch(() => console.error('Icon not found! -> ', action.img));
    });
  }

  onModalStatus(evt: { isOpen: boolean }) {
    this.showModal = evt.isOpen;
  }

  returnDorita = () => {
    this.showModal = false;
  };

  confirmationExit = () => {
    this.auth.logout();
  };
}

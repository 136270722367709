import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UniversalStorage } from './universal.storage';
const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  static instance: JwtService;
  constructor(private universalStorage: UniversalStorage) {
    JwtService.instance = this;
  }

  getToken(): string {
    return this.universalStorage.getItem('jwtToken');
  }

  saveItem(path: string, data: string) {
    this.universalStorage.setItem(path, data);
  }

  destroyToken() {
    this.universalStorage.removeItem('jwtToken');
    this.universalStorage.clear();
  }

  getInfo() {
    const token = this.getToken();
    if (token) {
      return jwtHelper.decodeToken(token);
    }
    return null;
  }

  hasExpired() {
    const token = this.getToken();
    return jwtHelper.isTokenExpired(token);
  }
}
